import request from '@/config/request.js'
const ApiAuthority = request.Authority
export default {
  name: 'user-authority',
  data () {
    return {
      tableList: [],
      edit: 'add',
      DetailFormVisible: false,
      authorityDetail: {
        path: '',
        component: '',
        icon: '',
        name: '',
        parentId: '',
        keepAlive: '',
        permId: '',
        singleMenu: true,
        isShow: true
      },
      authorityRules: {
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ],
        path: [
          { required: true, message: '请输入路径', trigger: 'blur' }
        ],
        component: [
          { required: true, message: '请输入模块地址', trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      parentList: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    onDelete (id) {
      this.$confirm('是否确定删除该菜单节点？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiAuthority.deletePermission.call(this, { permId: id }, (listData) => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getList()
        })
      }).catch(() => {
      })
    },
    /**
     * 新增或者编辑权限
     * */
    onDailogShow (row, type) {
      this.edit = type
      this.DetailFormVisible = true
      this.authorityDetail = Object.assign(this.authorityDetail, row)
      if (this.authorityDetail.parentId === 0) {
        this.authorityDetail.parentId = ''
      }
      this.getLevel()
    },
    /**
     * 获取当前一级目录
     * */
    getLevel () {
      this.getList({
        fn: (list) => {
          this.parentList = list.filter(item => {
            if (item.parentId === 0) {
              return item
            }
          })
        }
      })
    },
    resetauthority (formName) {
      this.$refs[formName].resetFields()
      this.DetailFormVisible = false
    },
    onSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.authorityDetail.createTime) {
            delete this.authorityDetail.createTime
          }
          if (this.edit === 'add') {
            delete this.authorityDetail.permId
          }
          let str = ''
          str = this.edit === 'add' ? 'createPermission' : 'updatePermission'
          ApiAuthority[str].call(this, this.authorityDetail, res => {
            this.getList()
            this.$refs[formName].resetFields()
            this.DetailFormVisible = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /*
    * 根据id获取当前父节点名称
    * */
    getParentString (id) {
      if (id === 0) {
        return ''
      } else {
        for (let i = 0; i < this.tableList.length; i++) {
          if (this.tableList[i].permId === id) {
            return this.tableList[i].name
          }
        }
      }
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      ApiAuthority.getPermissions.call(this, {}, (listData) => {
        this.tableList = listData
        fn && fn(listData)
      })
    }
  },
  mounted () {
    this.getList()
  }
}
