// 登录
import Login from './login/index.vue'
// 404
import notFindComponent from './404/notFindComponent.vue'
// 教务管理
import Education from './education/'
// 文件管理
import File from './file/'
// 首页
import Home from './home/'
// 直播课堂
import Course from './course/'
// 智能测评
import Evaluation from './evaluation/'
// two-Ball
import TwoBall from './twoColorBall/index.vue'
// two-Ball
// 用户管理
import User from './user/'
// 订单管理
import Order from './order/'
// 线索管理
import Clue from './clue/'
// 积分商城
import Mall from './mall/'

// 同步
import Synchro from './synchro/index.vue'

export default {
  Login,
  notFindComponent,
  Education,
  File,
  Home,
  Course,
  Evaluation,
  TwoBall,
  User,
  Order,
  Clue,
  Mall,
  Synchro
}
