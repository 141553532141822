// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Modules from '../components/modules'
// import RouteView from '../components/routeView'
//
// Vue.use(VueRouter)
// // name: 'index' 则不显示在tabs标签内
// const routes = [
//   {
//     path: '/',
//     redirect: to => {
//       return 'login'
//     },
//     hidden: true
//   },
//   {
//     path: '/login',
//     component: Modules.Login,
//     meta: {
//       name: '登陆'
//     }
//   }, {
//     path: '/education',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '教务管理',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'student-management'
//         },
//         hidden: true
//       },
//       {
//         path: 'student-management',
//         name: 'student-management',
//         meta: {
//           name: '学员管理'
//         },
//         component: Modules.Education.student.student
//       },
//       {
//         path: 'class-management',
//         name: 'class-management',
//         meta: {
//           name: '班级管理'
//         },
//         component: Modules.Education.classManagement
//       },
//       {
//         path: 'teacher-management',
//         name: 'teacher-management',
//         meta: {
//           name: '老师管理'
//         },
//         component: Modules.Education.teacher
//       },
//       {
//         path: 'student-attendance',
//         name: 'student-attendance',
//         meta: {
//           name: '学员考勤'
//         },
//         component: Modules.Education.studentAttendance
//       },
//       {
//         path: 'teacher-attendance',
//         name: 'teacher-attendance',
//         meta: {
//           name: '老师考勤'
//         },
//         component: Modules.Education.teacherAttendance
//       }
//       // ,
//       // {
//       //   path: 'order-service',
//       //   name: 'order-service',
//       //   meta: {
//       //     name: '订单服务'
//       //   },
//       //   component: Modules.Education.order
//       // }
//     ]
//   }, {
//     path: '/file',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '文件管理',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'admissions'
//         },
//         hidden: true
//       },
//       {
//         path: 'admissions',
//         name: 'admissions',
//         meta: {
//           name: '资料下载'
//         },
//         component: Modules.File.admissions.Index
//       },
//       {
//         path: 'admissions/details/:packId',
//         name: 'details',
//         meta: {
//           name: '资料详情'
//         },
//         component: Modules.File.admissions.Details
//       }
//     ]
//   }, {
//     path: '/home',
//     component: RouteView.RouteHome.Index,
//     meta: {
//       name: '校长首页',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'index'
//         },
//         hidden: true
//       },
//       {
//         path: 'index',
//         name: 'index',
//         meta: {
//           name: '首页展示'
//         },
//         component: Modules.Home.index
//       }
//     ]
//   }, {
//     path: '/course',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '直播课堂',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'courseware-query'
//         },
//         hidden: true
//       },
//       {
//         path: 'course-management',
//         name: 'course-management',
//         meta: {
//           name: '课程管理'
//         },
//         component: Modules.Course.courseManagement
//       },
//       {
//         path: 'courseware-query',
//         name: 'courseware-query',
//         meta: {
//           name: '课件查询'
//         },
//         component: Modules.Course.coursewareQuery
//       },
//       {
//         path: 'lesson-management',
//         name: 'lesson-management',
//         meta: {
//           name: '课节管理'
//         },
//         component: Modules.Course.lessonManagement.List
//       },
//       {
//         path: 'course-class-management',
//         name: 'course-class-management',
//         meta: {
//           name: '线上班级管理'
//         },
//         component: Modules.Course.classManagement
//       },
//       {
//         path: 'authority',
//         component: Modules.Course.authority
//       }
//     ]
//   }, {
//     path: '*',
//     component: Modules.notFindComponent
//   }, {
//     path: '/evaluation',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '测评中心',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'concentration'
//         },
//         hidden: true
//       },
//       {
//         path: 'concentration',
//         name: 'concentration',
//         meta: {
//           name: '专注力测评'
//         },
//         component: Modules.Evaluation.concentration
//       }
//     ]
//   }, {
//     path: '/teacher-assessment',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '老师测评库',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'teacher-assessment'
//         },
//         hidden: true
//       },
//       {
//         path: 'teacher-assessment',
//         name: 'teacher-assessment',
//         meta: {
//           name: '老师测评库'
//         },
//         component: Modules.Evaluation.teacherAssessment
//       },
//       {
//         path: 'authority',
//         component: Modules.Course.authority
//       }
//     ]
//   },
//   {
//     path: '/shuangseqiu',
//     component: Modules.TwoBall
//   }, {
//     path: '/mall',
//     component: RouteView.RouteIndex.Index,
//     meta: {
//       name: '商城管理',
//       keepAlive: true
//     },
//     children: [
//       {
//         path: '/',
//         redirect: to => {
//           return 'commodity'
//         },
//         hidden: true
//       },
//       {
//         path: 'commodity',
//         name: 'commodity',
//         meta: {
//           name: '积分商城'
//         },
//         component: Modules.Mall.commodity
//       },
//       {
//         path: 'record',
//         name: 'record',
//         meta: {
//           name: '兑换记录'
//         },
//         component: Modules.Mall.record
//       }
//     ]
//   }
// ]
//
// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })
//
// export default router

import Vue from 'vue'
import VueRouter from 'vue-router'
import Modules from '../components/modules'
import RouteView from '../components/routeView'
Vue.use(VueRouter)
// name: 'index' 则不显示在tabs标签内
const routes = [
  {
    path: '/',
    redirect: to => {
      return 'login'
    },
    hidden: true
  },
  {
    path: '/login',
    component: Modules.Login,
    meta: {
      name: '登陆'
    }
  },
  {
    path: '/synchro',
    component: RouteView.RouteIndex.Index,
    meta: {
      name: '同步机'
    },
    children: [
      {
        path: '/',
        redirect: to => {
          return 'synchro'
        },
        hidden: true
      },
      {
        path: 'synchro',
        name: 'synchro',
        meta: {
          name: '同步到汉王'
        },
        component: Modules.Synchro
      }
    ]
  }, {
    path: '*',
    component: Modules.notFindComponent
  }
]

// console.log(RouteView)
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
