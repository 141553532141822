export default {
  isvalidPhone (str) {
    var partten = /^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i
    return partten.test(str)
  },
  isvalidEmail (str) {
    var partten = /^([a-zA-Z0-9])$/
    return partten.test(str)
  }
}
