import request from '@/config/request.js'
const ApiRole = request.Role
export default {
  name: 'user-role',
  data () {
    return {
      tableList: [],
      edit: 'add',
      roleDetail: {
        roleName: '',
        roleId: ''
      },
      DetailFormVisible: false,
      roleRules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      RoleFormVisible: false,
      authorityTable: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedList: [],
      roleId: '',
      defaultCheck: [],
      isChecked: false
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    onDelete (id) {
      this.$confirm('是否确定删除该角色？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiRole.deleteRole.call(this, { roleId: id }, (listData) => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.getList()
        })
      }).catch(() => {
      })
    },
    /**
     * 新增或者编辑角色
     * */
    onDailogRoleShow (row, type) {
      this.edit = type
      this.DetailFormVisible = true
      this.roleDetail = Object.assign(this.roleDetail, row)
      this.roleId = row.roleId
    },
    resetRoleFrom (formName) {
      this.$refs[formName].resetFields()
      this.DetailFormVisible = false
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    onSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.roleDetail.createTime) {
            delete this.roleDetail.createTime
          }
          if (this.edit === 'add') {
            delete this.roleDetail.roleId
          }
          let str = ''
          str = this.edit === 'add' ? 'createRole' : 'updateRole'
          ApiRole[str].call(this, this.roleDetail, res => {
            this.getList()
            this.$refs[formName].resetFields()
            this.DetailFormVisible = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCheckChange (data, checked, indeterminate) {
      // console.log(data, checked, indeterminate)
    },
    handleCheck (data, checked) {
      this.isChecked = true
      // console.log(...checked.halfCheckedKeys, ...checked.checkedKeys)
      this.checkedList = [...checked.halfCheckedKeys, ...checked.checkedKeys]
    },
    onAuthoritySave () {
      let arr = []
      arr = this.checkedList.map(item => {
        return {
          permId: item,
          roleId: this.roleId
        }
      })
      if (this.isChecked === false) {
        this.RoleFormVisible = false
      } else {
        ApiRole.bindRolePermission.call(this, arr, res => {
          this.RoleFormVisible = false
          this.isChecked = false
          this.getList()
        })
      }
    },
    getAuthorityTree (row) {
      this.roleId = row.roleId
      ApiRole.getPermissionTree.call(this, {}, res => {
        this.RoleFormVisible = true
        let arr = []
        if (row.treePermisson && row.treePermisson.length > 0) {
          row.treePermisson.map(item => {
            if (item.children && item.children.length > 0) {
              for (let i = 0; i < item.children.length; i++) {
                arr = [...arr, item.children[i].id]
              }
            } else {
              arr = [...arr, item.id]
            }
          })
        }
        this.defaultCheck = arr
        this.authorityTable = res
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      const that = this
      ApiRole.getRoles.call(this, {}, (listData) => {
        that.tableList = listData
      })
    }
  },
  mounted () {
    this.getList()
  }
}
