export default {
  name: 'two-color-ball',
  data () {
    return {
      // 原始数
      ball: [
        [1, 2, 3, 4, 5],
        [6, 7, 8, 9, 10],
        [11, 12, 13, 14, 15],
        [16, 17, 18, 19, 20, 21],
        [22, 23, 24, 25, 26, 27],
        [28, 29, 30, 31, 32, 33]
      ],
      // 次数
      number: [2, 2, 0, 1, 1, 0],
      // 所有可能
      arr: {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
        data6: []
      },
      index: 0,
      other: [1, 5, 20],
      loading: false,
      list: []
    }
  },
  methods: {
    removeDomain (index) {
      this.other.splice(index, 1)
    },
    addDomain () {
      this.other = [...this.other, 0]
    },
    onSubmit () {
      console.log(this.$data)
    },
    getArr (data, index, arr, o) {
      if (o > 0) {
        index += 1
        for (let i = 0; i < data.length; i++) {
          if (this.other.indexOf(data[i]) > -1) {
            continue
          }
          if (arr.length > 0) {
            const p = arr[arr.length - 1]
            if (arr[arr.length - 1].length < o && data[i] > p[p.length - 1]) {
              arr[arr.length - 1] = [...arr[arr.length - 1], data[i]]
            } else {
              let newArr = [...arr[arr.length - 1]]
              newArr = newArr.splice(0, index - 1)
              arr.push([...newArr, data[i]])
            }
          } else {
            arr.push([data[i]])
          }
          if (index < o) {
            const item = [...data]
            item.splice(0, i + 1)
            this.getArr(item, index, arr, o)
          }
        }
      }
    },
    getList () {
      this.arr = {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
        data6: []
      }
      for (let i = 0; i < this.number.length; i++) {
        if (i === 0) {
          this.getArr(this.ball[i], 0, this.arr.data1, this.number[i])
          const aa = []
          for (let six = 0; six < this.arr.data1.length; six++) {
            if (this.arr.data1[six].length === this.number[i]) {
              aa.push(this.arr.data1[six])
            }
          }
          this.arr.data1 = aa
        } else if (i === 1) {
          const bb = []
          this.getArr(this.ball[i], 0, this.arr.data2, this.number[i])
          for (let six = 0; six < this.arr.data2.length; six++) {
            if (this.arr.data2[six].length === this.number[i]) {
              bb.push(this.arr.data2[six])
            }
          }
          this.arr.data2 = bb
        } else if (i === 2) {
          const cc = []
          this.getArr(this.ball[i], 0, this.arr.data3, this.number[i])
          for (let six = 0; six < this.arr.data3.length; six++) {
            if (this.arr.data3[six].length === this.number[i]) {
              cc.push(this.arr.data3[six])
            }
          }
          this.arr.data3 = cc
        } else if (i === 3) {
          const dd = []
          this.getArr(this.ball[i], 0, this.arr.data4, this.number[i])
          for (let six = 0; six < this.arr.data4.length; six++) {
            if (this.arr.data4[six].length === this.number[i]) {
              dd.push(this.arr.data4[six])
            }
          }
          this.arr.data4 = dd
        } else if (i === 4) {
          const ee = []
          this.getArr(this.ball[i], 0, this.arr.data5, this.number[i])
          for (let six = 0; six < this.arr.data5.length; six++) {
            if (this.arr.data5[six].length === this.number[i]) {
              ee.push(this.arr.data5[six])
            }
          }
          this.arr.data5 = ee
        } else {
          const ff = []
          this.getArr(this.ball[i], 0, this.arr.data6, this.number[i])
          for (let six = 0; six < this.arr.data6.length; six++) {
            if (this.arr.data6[six].length === this.number[i]) {
              ff.push(this.arr.data6[six])
            }
          }
          this.arr.data6 = ff
        }
      }
      console.log(this.$data)
      const dataList = []
      if (this.arr.data1.length > 0) {
        for (let a = 0; a < this.arr.data1.length; a++) {
          const aa = [...this.arr.data1[a]]
          if (this.arr.data2.length > 0) {
            for (let b = 0; b < this.arr.data2.length; b++) {
              const bb = [...this.arr.data2[b]]
              if (this.arr.data3.length > 0) {
                for (let c = 0; c < this.arr.data3.length; c++) {
                  const cc = [...this.arr.data3[c]]
                  if (this.arr.data4.length > 0) {
                    for (let d = 0; d < this.arr.data4.length; d++) {
                      const dd = [...this.arr.data4[d]]
                      if (this.arr.data5.length > 0) {
                        for (let e = 0; e < this.arr.data5.length; e++) {
                          const ee = [...this.arr.data5[e]]
                          if (this.arr.data6.length > 0) {
                            for (let f = 0; f < this.arr.data6.length; f++) {
                              const ff = [...this.arr.data6[f]]
                              dataList.push([...aa, ...bb, ...cc, ...dd, ...ee, ...ff])
                            }
                          } else {
                            dataList.push([...aa, ...bb, ...cc, ...dd, ...ee])
                          }
                        }
                      } else {
                        if (this.arr.data6.length > 0) {
                          for (let f = 0; f < this.arr.data6.length; f++) {
                            const ff = [...this.arr.data6[f]]
                            dataList.push([...aa, ...bb, ...cc, ...dd, ...ff])
                          }
                        } else {
                          dataList.push([...aa, ...bb, ...cc, ...dd])
                        }
                      }
                    }
                  } else {
                    if (this.arr.data5.length > 0) {
                      for (let e = 0; e < this.arr.data5.length; e++) {
                        const ee = [...this.arr.data5[e]]
                        if (this.arr.data6.length > 0) {
                          for (let f = 0; f < this.arr.data6.length; f++) {
                            const ff = [...this.arr.data6[f]]
                            dataList.push([...aa, ...bb, ...cc, ...ee, ...ff])
                          }
                        } else {
                          dataList.push([...aa, ...bb, ...cc, ...ee])
                        }
                      }
                    } else {
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...bb, ...cc, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...bb, ...cc])
                      }
                    }
                  }
                }
              } else {
                if (this.arr.data4.length > 0) {
                  for (let d = 0; d < this.arr.data4.length; d++) {
                    const dd = [...this.arr.data4[d]]
                    if (this.arr.data5.length > 0) {
                      for (let e = 0; e < this.arr.data5.length; e++) {
                        const ee = [...this.arr.data5[e]]
                        if (this.arr.data6.length > 0) {
                          for (let f = 0; f < this.arr.data6.length; f++) {
                            const ff = [...this.arr.data6[f]]
                            dataList.push([...aa, ...bb, ...dd, ...ee, ...ff])
                          }
                        } else {
                          dataList.push([...aa, ...bb, ...dd, ...ee])
                        }
                      }
                    } else {
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...bb, ...dd, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...bb, ...dd])
                      }
                    }
                  }
                } else {
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...bb, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...bb, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...aa, ...bb, ...ff])
                      }
                    } else {
                      dataList.push([...aa, ...bb])
                    }
                  }
                }
              }
            }
          } else {
            if (this.arr.data3.length > 0) {
              for (let c = 0; c < this.arr.data3.length; c++) {
                const cc = [...this.arr.data3[c]]
                if (this.arr.data4.length > 0) {
                  for (let d = 0; d < this.arr.data4.length; d++) {
                    const dd = [...this.arr.data4[d]]
                    if (this.arr.data5.length > 0) {
                      for (let e = 0; e < this.arr.data5.length; e++) {
                        const ee = [...this.arr.data5[e]]
                        if (this.arr.data6.length > 0) {
                          for (let f = 0; f < this.arr.data6.length; f++) {
                            const ff = [...this.arr.data6[f]]
                            dataList.push([...aa, ...cc, ...dd, ...ee, ...ff])
                          }
                        } else {
                          dataList.push([...aa, ...cc, ...dd, ...ee])
                        }
                      }
                    } else {
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...cc, ...dd, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...cc, ...dd])
                      }
                    }
                  }
                } else {
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...cc, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...cc, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...aa, ...cc, ...ff])
                      }
                    } else {
                      dataList.push([...aa, ...cc])
                    }
                  }
                }
              }
            } else {
              if (this.arr.data4.length > 0) {
                for (let d = 0; d < this.arr.data4.length; d++) {
                  const dd = [...this.arr.data4[d]]
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...aa, ...dd, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...aa, ...dd, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...aa, ...dd, ...ff])
                      }
                    } else {
                      dataList.push([...aa, ...dd])
                    }
                  }
                }
              } else {
                if (this.arr.data5.length > 0) {
                  for (let e = 0; e < this.arr.data5.length; e++) {
                    const ee = [...this.arr.data5[e]]
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...aa, ...ee, ...ff])
                      }
                    } else {
                      dataList.push([...aa, ...ee])
                    }
                  }
                } else {
                  if (this.arr.data6.length > 0) {
                    for (let f = 0; f < this.arr.data6.length; f++) {
                      const ff = [...this.arr.data6[f]]
                      dataList.push([...aa, ...ff])
                    }
                  } else {
                    dataList.push([...aa])
                  }
                }
              }
            }
          }
        }
      } else {
        console.log('aa')
        if (this.arr.data2.length > 0) {
          for (let b = 0; b < this.arr.data2.length; b++) {
            const bb = [...this.arr.data2[b]]
            if (this.arr.data3.length > 0) {
              for (let c = 0; c < this.arr.data3.length; c++) {
                const cc = [...this.arr.data3[c]]
                if (this.arr.data4.length > 0) {
                  for (let d = 0; d < this.arr.data4.length; d++) {
                    const dd = [...this.arr.data4[d]]
                    if (this.arr.data5.length > 0) {
                      for (let e = 0; e < this.arr.data5.length; e++) {
                        const ee = [...this.arr.data5[e]]
                        if (this.arr.data6.length > 0) {
                          for (let f = 0; f < this.arr.data6.length; f++) {
                            const ff = [...this.arr.data6[f]]
                            dataList.push([...bb, ...cc, ...dd, ...ee, ...ff])
                          }
                        } else {
                          dataList.push([...bb, ...cc, ...dd, ...ee])
                        }
                      }
                    } else {
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...bb, ...cc, ...dd, ...ff])
                        }
                      } else {
                        dataList.push([...bb, ...cc, ...dd])
                      }
                    }
                  }
                } else {
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...bb, ...cc, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...bb, ...cc, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...bb, ...cc, ...ff])
                      }
                    } else {
                      dataList.push([...bb, ...cc])
                    }
                  }
                }
              }
            } else {
              if (this.arr.data4.length > 0) {
                for (let d = 0; d < this.arr.data4.length; d++) {
                  const dd = [...this.arr.data4[d]]
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...bb, ...dd, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...bb, ...dd, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...bb, ...dd, ...ff])
                      }
                    } else {
                      dataList.push([...bb, ...dd])
                    }
                  }
                }
              } else {
                if (this.arr.data5.length > 0) {
                  for (let e = 0; e < this.arr.data5.length; e++) {
                    const ee = [...this.arr.data5[e]]
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...bb, ...ee, ...ff])
                      }
                    } else {
                      dataList.push([...bb, ...ee])
                    }
                  }
                } else {
                  if (this.arr.data6.length > 0) {
                    for (let f = 0; f < this.arr.data6.length; f++) {
                      const ff = [...this.arr.data6[f]]
                      dataList.push([...bb, ...ff])
                    }
                  } else {
                    dataList.push([...bb])
                  }
                }
              }
            }
          }
        } else {
          if (this.arr.data3.length > 0) {
            for (let c = 0; c < this.arr.data3.length; c++) {
              const cc = [...this.arr.data3[c]]
              if (this.arr.data4.length > 0) {
                for (let d = 0; d < this.arr.data4.length; d++) {
                  const dd = [...this.arr.data4[d]]
                  if (this.arr.data5.length > 0) {
                    for (let e = 0; e < this.arr.data5.length; e++) {
                      const ee = [...this.arr.data5[e]]
                      if (this.arr.data6.length > 0) {
                        for (let f = 0; f < this.arr.data6.length; f++) {
                          const ff = [...this.arr.data6[f]]
                          dataList.push([...cc, ...dd, ...ee, ...ff])
                        }
                      } else {
                        dataList.push([...cc, ...dd, ...ee])
                      }
                    }
                  } else {
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...cc, ...dd, ...ff])
                      }
                    } else {
                      dataList.push([...cc, ...dd])
                    }
                  }
                }
              } else {
                if (this.arr.data5.length > 0) {
                  for (let e = 0; e < this.arr.data5.length; e++) {
                    const ee = [...this.arr.data5[e]]
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...cc, ...ee, ...ff])
                      }
                    } else {
                      dataList.push([...cc, ...ee])
                    }
                  }
                } else {
                  if (this.arr.data6.length > 0) {
                    for (let f = 0; f < this.arr.data6.length; f++) {
                      const ff = [...this.arr.data6[f]]
                      dataList.push([...cc, ...ff])
                    }
                  } else {
                    dataList.push([...cc])
                  }
                }
              }
            }
          } else {
            if (this.arr.data4.length > 0) {
              for (let d = 0; d < this.arr.data4.length; d++) {
                const dd = [...this.arr.data4[d]]
                if (this.arr.data5.length > 0) {
                  for (let e = 0; e < this.arr.data5.length; e++) {
                    const ee = [...this.arr.data5[e]]
                    if (this.arr.data6.length > 0) {
                      for (let f = 0; f < this.arr.data6.length; f++) {
                        const ff = [...this.arr.data6[f]]
                        dataList.push([...dd, ...ee, ...ff])
                      }
                    } else {
                      dataList.push([...dd, ...ee])
                    }
                  }
                } else {
                  if (this.arr.data6.length > 0) {
                    for (let f = 0; f < this.arr.data6.length; f++) {
                      const ff = [...this.arr.data6[f]]
                      dataList.push([...dd, ...ff])
                    }
                  } else {
                    dataList.push([...dd])
                  }
                }
              }
            } else {
              if (this.arr.data5.length > 0) {
                for (let e = 0; e < this.arr.data5.length; e++) {
                  const ee = [...this.arr.data5[e]]
                  if (this.arr.data6.length > 0) {
                    for (let f = 0; f < this.arr.data6.length; f++) {
                      const ff = [...this.arr.data6[f]]
                      dataList.push([...ee, ...ff])
                    }
                  } else {
                    dataList.push([...ee])
                  }
                }
              } else {
                if (this.arr.data6.length > 0) {
                  for (let f = 0; f < this.arr.data6.length; f++) {
                    const ff = [...this.arr.data6[f]]
                    dataList.push([...ff])
                  }
                } else {
                  dataList.push([...[]])
                }
              }
            }
          }
        }
      }
      this.list = dataList
    }
  },
  mounted () {}
}
