import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiRecord = request.Mall.Record
export default {
  name: 'mall-record',
  data () {
    return {
      loadtext: '正在加载中',
      loadtextNum: '',
      search_data: {
        filters: [{
          field: 'status',
          operator: 'eq',
          value: ''
        }, {
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }]
      },
      search_filter: {
        startDate: '',
        endDate: ''
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      recordStatus: [
        {
          label: '待审核',
          value: 1
        }, {
          label: '审核通过',
          value: 2
        }, {
          label: '审核拒绝',
          value: 3
        }
      ],
      qiniu: setting.gbs.qiniu,
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: '',
        ajaxType: 'form'
      },
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    /**
     * 通过或者拒绝
     * */
    getStautschange (type, row) {
      this.$confirm('确' + (type === 1 ? '通过' : '拒绝') + '该记录吗?是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          productRecordId: row.productRecordId
        }
        ApiRecord[type === 1 ? 'acceptExchange' : 'refuseExchange'].call(this, data, () => {
          this.getList()
        })
      }).catch(() => {
      })
    },
    /**
     * 通过ID匹配数据
     * */
    getStatusString (data, value) {
      console.log(data, '12')
      for (let j = 0; j < this[data].length; j++) {
        if (this[data][j].value === value) {
          return this[data][j].label
        }
      }
    },
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiRecord.exportProductRecord.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'status',
          operator: 'eq',
          value: ''
        }, {
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }]
      }
      this.search_filter = {
        startDate: '',
        endDate: ''
      }
      this.onSearch()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    onLoading () {
      switch (this.loadtextNum.length) {
        case 0:
          this.loadtextNum = '.'
          break
        case 1:
          this.loadtextNum = '..'
          break
        case 2:
          this.loadtextNum = '...'
          break
        default:
          this.loadtextNum = ''
          break
      }
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      // const time = setInterval(this.onLoading, 500)
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'productRecordId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      const filter = this.search_filter

      // for (const item in this.search_filter) {
      //   if (filter === null) {
      //     if (this.search_filter[item] !== '') {
      //       filter = Object.assign({}, { item: this.search_filter[item] })
      //     }
      //   } else {
      //     if (this.search_filter[item] !== '') {
      //       filter = Object.assign(filter, { item: this.search_filter[item] })
      //     }
      //   }
      // }
      data.data = filter
      ApiRecord.getProductRecord.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
