import request from '@/config/request.js'
const ApiClue = request.Clue
const ApiTeacherAssessment = request.teacherAssessment
export default {
  name: 'clue-management',
  data () {
    const checkfollow = (rule, value, callback) => {
      if (this.clueDetails.followPersonType === 0) {
        callback()
      } else {
        if (this.clueDetails.followPerson && this.clueDetails.followPerson !== '') {
          callback()
        } else {
          callback(new Error('请选择跟进人!'))
        }
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码！'))
      } else {
        var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
    }
    const checkMoney = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入缴费金额'))
      } else {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的缴费金额'))
        } else {
          callback()
        }
      }
    }
    const checkServer = (rule, value, callback) => {
      if (this.servicesArr.length === 0) {
        callback(new Error('请至少选择一种服务'))
      } else {
        if (this.servicesArr.indexOf('其他') > -1) {
          if (this.applyDetails.serviceNote === '') {
            callback(new Error('请填写其他相关服务'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
      // if (this.servicesArr.length === 0 && this.applyDetails.serviceNote === '') {
      //   callback(new Error('请至少选择一种服务'))
      // } else {
      //   callback()
      // }
    }
    const checkTime = (rule, value, callback) => {
      if (this.applyDetails.serviceStart === '' || this.applyDetails.serviceEnd === '') {
        callback(new Error('请选择服务起止时间'))
      } else {
        const end = this.applyDetails.serviceEnd
        let str = this.applyDetails.serviceStart
        str = new Date(str)
        const str1 = str.getTime()
        // if (end > str.setMonth(str.getMonth() + 3)) {
        //   callback(new Error('单笔订单服务时间最多不超过三个月'))
        // } else
        if (str1 > end) {
          callback(new Error('开始时间不能大于结束时间'))
        } else {
          callback()
        }
      }
    }
    return {
      loadtext: '正在加载中',
      search_data: {
        filters: [{
          field: 'studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'followStatus',
          operator: 'eq',
          value: ''
        }, {
          field: 'followStep',
          operator: 'eq',
          value: ''
        }, {
          field: 'intentionLevel',
          operator: 'eq',
          value: ''
        }, {
          field: 'developSource',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      followStatu: [
        {
          label: '待跟进',
          value: 1
        }, {
          label: '跟进中',
          value: 2
        }, {
          label: '已成交',
          value: 3
        }
      ],
      followStep: [
        {
          label: '建立联系',
          value: 1
        }, {
          label: '确认意见',
          value: 2
        }, {
          label: '承诺到访',
          value: 3
        }, {
          label: '试托体验',
          value: 4
        }, {
          label: '完成缴费',
          value: 5
        }
      ],
      developSource: [
        {
          label: '门店到访',
          value: 1
        }, {
          label: '转介绍',
          value: 2
        }, {
          label: '地摊活动',
          value: 3
        }, {
          label: '招生宝',
          value: 4
        }, {
          label: '网络搜索',
          value: 5
        }, {
          label: '其他',
          value: 6
        }
      ],
      followType: [
        {
          label: '电话',
          value: 1
        }, {
          label: '微信',
          value: 2
        }, {
          label: '到访',
          value: 3
        }, {
          label: '其他',
          value: 4
        }
      ],
      intentionLevel: [
        {
          label: '低',
          value: 1
        }, {
          label: '中',
          value: 2
        }, {
          label: '高',
          value: 3
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: ''
      },
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      },
      DetailFormVisible: false,
      applyFormVisible: false,
      clueDetails: {
        // 学生姓名
        studentName: '',
        // 性别  true for 男, false for 女
        gender: true,
        // 家长姓名
        parentName: '妈妈',
        // 联系方式
        mobile: '',
        // 意向级别(1 for 低，2 for 中，3 for 高)
        intentionLevel: null,
        // 学生来源(1 for 门店到访，2 for 转介绍，3 for 地摊活动，4 for 招生宝，5 for 网络搜索，6 for 其它)
        developSource: null,
        // 这里是zone teacher的teacherId,如不选择跟进人，这个可默认值为0
        followPerson: null,
        note: '',
        nickname: '',
        followPersonType: 0
      },
      applyDetails: {
        clueId: '',
        // 班级Id
        studyClass: {
          studyClassId: ''
        },
        // 老师ID
        zoneTeacher: {
          teacherId: ''
        },
        // 手机号
        mobile: '',
        // 学生名称
        studentName: '',
        // 家长名称
        parentName: '',
        // 学生性别
        gender: true,
        // 金额
        total: null,
        // 开通服务
        services: '',
        // 服务备注
        serviceNote: '',
        // 服务开始时间
        serviceStart: '',
        // 服务结束时间
        serviceEnd: '',
        // 报名备注
        note: '',
        payTime: ''
      },
      followDetails: {
        clueId: '',
        // 跟进方式（1-电话，2-微信，3-到访，4-其他)
        followType: null,
        // 跟进阶段(1 for 建立联系，2 for 确认意见, 3 for 承诺到访，4 for 试托体验, 5 完成缴费)
        followStep: null,
        // 跟进人，这里是zone teacher的teacherId
        followPerson: null,
        followDate: null,
        note: ''
      },
      grade: [
        {
          name: '一年级',
          value: 'FIRST_GRADE'
        },
        {
          name: '二年级',
          value: 'SECOND_GRADE'
        },
        {
          name: '三年级',
          value: 'THIRD_GRADE'
        },
        {
          name: '四年级',
          value: 'FOURTH_GRADE'
        },
        {
          name: '五年级',
          value: 'FIFTH_GRADE'
        },
        {
          name: '六年级',
          value: 'SIXTH_GRADE'
        },
        {
          name: '七年级',
          value: 'SEVENTH_GRADE'
        },
        {
          name: '八年级',
          value: 'EIGHTH_GRADE'
        },
        {
          name: '九年级',
          value: 'NINTH_GRADE'
        },
        {
          name: '幼小',
          value: 'YOUNG_CONNECTION'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      applyRules: {
        'studyClass.studyClassId': [
          { required: true, message: '请安排学生入班', trigger: 'change' }
        ],
        total: [
          { required: true, validator: checkMoney, trigger: 'blur' }
        ],
        changeServices: [
          { required: true, validator: checkServer, trigger: 'blur' }
        ],
        changeData: [
          { required: true, validator: checkTime, trigger: 'change' }
        ],
        payTime: [
          { required: true, message: '请选择缴费时间', trigger: 'change' }
        ],
        grade: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        'zoneTeacher.teacherId': [
          { required: true, message: '请选择经办人', trigger: 'change' }
        ],
        servicesArr: [
          { required: true, validator: checkServer, trigger: 'blur' }
        ]
      },
      clueRules: {
        studentName: [
          { required: true, message: '请输入学生姓名', trigger: 'blur' }
        ],
        mobile: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        intentionLevel: [
          { required: true, message: '请选择意向级别', trigger: 'change' }
        ],
        developSource: [
          { required: true, message: '请选择学员来源', trigger: 'change' }
        ],
        followPersonType: [
          { required: true, validator: checkfollow, trigger: 'change' }
        ]
      },
      formLabelWidth: '120px',
      edit: 'add',
      studyClass: [],
      zoneTeacher: [],
      pickerOptions: {
        disabledDate (time) {
          const nowdate = new Date()
          return time.getTime() > nowdate.setMonth(nowdate.getMonth() + 3)
        }
      },
      DetailVisible: false,
      services: [
        {
          label: '午托',
          value: 'MORNING'
        }, {
          label: '下午托',
          value: 'AFTERNOON'
        }, {
          label: '作业托',
          value: 'WORK'
        }, {
          label: '其他',
          value: 'OTHER'
        }
      ],
      servicesArr: [],
      /**
       * 跟进记录
       * */
      followRecord: false,
      followTable: [],
      followRecordVisible: false,
      followRules: {
        followType: [
          { required: true, message: '请选择跟进方式', trigger: 'change' }
        ],
        followStep: [
          { required: true, message: '请选择跟进阶段', trigger: 'change' }
        ],
        followDate: [
          { required: true, message: '请选择跟进时间', trigger: 'change' }
        ],
        followPerson: [
          { required: true, message: '请选择跟进人', trigger: 'change' }
        ]
      },
      followRecordDetails: {
        // 线索ID
        clueId: '',
        // 跟进方式（1-电话，2-微信，3-到访，4-其他)
        followType: null,
        // 跟进阶段(1 for 建立联系，2 for 确认意见, 3 for 承诺到访，4 for 试托体验, 5 完成缴费)
        followStep: null,
        // 跟进人，这里是zone teacher的teacherId
        followPerson: null,
        followDate: null,
        note: ''
      },
      clueId: ''
    }
  },
  watch: {
    'applyDetails.serviceNote': function (str) {
      if (str) {
        if (this.servicesArr.indexOf('其他') < 0) {
          this.servicesArr.push('其他')
        }
      }
    },
    servicesArr: function (str) {
      if (str.indexOf('其他') < 0) {
        if (this.applyDetails.serviceNote) {
          this.applyDetails.serviceNote = ''
        }
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiClue.exportStudentClues.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    onFollowRecordShow () {
      this.getZoneTeacher()
      console.log(this.clueId)
      this.followRecordDetails.clueId = this.clueId
      this.followRecordVisible = true
    },
    onSaveFollow (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiClue.createClueRecord.call(this, that.followRecordDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            this.getList()
            this.$refs[formName].resetFields()
            this.followRecordVisible = false
            this.followRecord = false
          })
        }
      })
    },
    getFollow (row) {
      this.clueId = row.clueId
      this.followTable = row.record ? row.record : []
      this.followRecord = true
    },
    onApplyShow (row) {
      this.clueId = row.clueId
      this.getStudyClassByZoneId()
      this.getZoneTeacher()
      this.applyDetails.studentName = row.studentName
      this.applyDetails.mobile = row.mobile
      this.applyDetails.gender = row.gender
      this.applyDetails.parentName = row.parentName
      this.applyFormVisible = true
    },
    getStudyClassByZoneId () {
      ApiClue.getStudyClassByZoneId.call(this, {}, (listData) => {
        this.studyClass = listData
      })
    },
    onSaveApply (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const strArr = []
          if (that.servicesArr.length > 0) {
            for (let j = 0; j < that.servicesArr.length; j++) {
              for (let i = 0; i < that.services.length; i++) {
                if (that.servicesArr[j] === that.services[i].label) {
                  strArr.push(that.services[i].value)
                }
              }
            }
          }
          that.applyDetails.services = strArr.join(',')
          that.applyDetails.clueId = this.clueId
          ApiClue.createApplication.call(this, that.applyDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            this.getList()
            this.$refs[formName].resetFields()
            that.servicesArr = []
            this.applyFormVisible = false
          })
        }
      })
    },
    closeOrderFollow (formName) {
      this.$refs[formName].resetFields()
      this.followRecordVisible = false
    },
    closeOrder (formName) {
      this.$refs[formName].resetFields()
      this.applyFormVisible = false
    },
    onDeleteClue (id) {
      const that = this
      this.$confirm('是否确定删除该线索？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiClue.deleteClue.call(this, { clueId: id }, () => {
          that.getList()
        })
      }).catch(() => {
      })
    },
    resetSearch (formName) {
      this.$refs[formName].resetFields()
      this.getList()
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let str = 'add'
          str = this.edit === 'add' ? 'createClue' : 'updateStudentOrder'
          ApiClue[str].call(this, that.clueDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            this.getList()
            this.$refs[formName].resetFields()
            this.DetailFormVisible = false
          })
        }
      })
    },
    onShow (row, type) {
      this.edit = type
      this.getZoneTeacher()
      this.$nextTick(() => {
        this.DetailFormVisible = true
      })
    },
    getZoneTeacher () {
      ApiTeacherAssessment.getZoneTeachers.call(this, {}, (item) => {
        this.zoneTeacher = item
      })
    },
    /**
     * 通过ID匹配数据
     * */
    getStatusString (data, value) {
      for (let j = 0; j < this[data].length; j++) {
        if (this[data][j].value === value) {
          return this[data][j].label
        }
      }
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {}
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'clueId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiClue.getClues.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
