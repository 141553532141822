// 转化成数组
const TO_ARRAY = function (start, end) {
  return [].slice.call(arguments)
}
export default {
  /**
   * 转化ele能用的时间格式
   * @param  {string}   start 开始时间 /00:00:00/
   * @param  {string}   end   结束时间 /00:00:00/
   */

  to_time (start, end) {
    const arr = TO_ARRAY(start, end)
    const result = []
    arr.forEach(function (item) {
      result.push(timeStandard(item))
    })
    function timeStandard (value) {
      const arry = value.split(':')
      if (!arry[0]) { console.log('时间格式错误!') }
      if (!arry[2]) arry[2] = 0
      return new Date(2016, 9, 10, arry[0], arry[1], arry[2])
    }
    return result
  },
  to_up_time (time) {
    if (Boolean(time) === false) {
      console.log('"to_up_time":时间格式错误!')
      return false
    }
    const arr = []
    const reg = /[0-2][\d]:[0-6][\d]:[0-6][\d]/
    time.forEach(function (item) {
      arr.push(reg.exec(item)[0])
    })
    return arr
  }
}
