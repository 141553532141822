export default {
  name: 'lesson-management-student',
  props: ['studentTable'],
  data () {
    return {
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      userId: ''
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getDetail (row) {
      this.$emit('onGetClass', { userId: row.userId })
    }
  },
  mounted () {
    // this.getList()
  }
}
