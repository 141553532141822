import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiCommodity = request.Mall.Commodity
export default {
  name: 'mall-commodity',
  data () {
    const checkInt = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入正确的数值'))
      } else {
        var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/
        if (reg.test(value) === false) {
          callback(new Error('数值输入有误'))
        } else {
          callback()
        }
      }
    }
    return {
      loadtext: '正在加载中',
      myHeaders: { Authorization: this.$store.state.login.token },
      qiniu: setting.gbs.qiniu,
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      teacherSignState: [
        {
          label: '正常',
          value: 'NORMAL'
        }, {
          label: '异常',
          value: 'ABNORMAL'
        }
      ],
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: '',
        ajaxType: 'form'
      },
      exportRules: {
        startDate: [
          {
            required: true,
            message: '请选择开始时间',
            trigger: 'change'
          }
        ],
        endDate: [
          {
            required: true,
            message: '请选择结束时间',
            trigger: 'change'
          }
        ]
      },
      productRules: {
        productName: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        score: [
          { required: true, validator: checkInt, trigger: 'blur' }
        ],
        stock: [
          { required: true, validator: checkInt, trigger: 'blur' }
        ]
      },
      formLabelWidth: '120px',
      shopDailog: false,
      shopDetails: {
        productName: '',
        productImg: '',
        description: '',
        stock: 0,
        score: 0,
        status: '',
        remark: ''
      },
      imageUrl: [],
      imageUrlName: [],
      disabled: false,
      fileList: [],
      imageIndex: -1,
      shopStatus: [
        {
          label: '上架',
          value: 2
        }, {
          label: '下架',
          value: 1
        }
      ],
      isEdit: 'add',
      search_data: {
        filters: [{
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'ge',
          value: ''
        }, {
          field: 'morningSignState',
          operator: 'eq',
          value: ''
        }]
      }

    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    mouseLeave () {
      this.imageIndex = -1
    },
    handleRemove (file) {
      this.fileList.map(item => {
        if (item.url.indexOf(file.url) > -1) {
          this.fileList.splice(this.fileList.indexOf(item), 1)
        }
      })
      this.imageUrlName.map(item => {
        if (file.url.indexOf(item) > -1) {
          this.imageUrlName.splice(this.imageUrlName.indexOf(item), 1)
        }
      })
    },
    mouseOver (index) {
      this.imageIndex = index
      console.log(123, index)
    },
    onAdd () {
      this.isEdit = 'add'
      this.shopDailog = true
      this.shopDetails = {
        productName: '',
        productImg: '',
        description: '',
        stock: 0,
        score: 0,
        status: '',
        remark: ''
      }
    },
    onEdit (row) {
      this.isEdit = 'edit'
      this.shopDailog = true
      this.shopDetails = Object.assign(this.shopDetails, row)
      // this.imageUrl = this.qiniu + row.productImg
      this.imageUrlName = []
      if (this.shopDetails.productImg) {
        // this.fileList = row.productImg
        this.fileList = [...row.productImg]
        this.shopDetails.productImg.map(item => {
          this.imageUrlName.push(item.name)
        })
      }
      console.log(this.shopDetails)
    },
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.imageUrlName = []
      this.fileList = []
    },
    onDelete (row) {
      this.$confirm('确认删除该奖品吗?删除数据不可恢复，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          productId: row.productId
        }
        ApiCommodity.deleteScoreProduct.call(this, data, () => {
          this.getList()
        })
      }).catch(() => {
      })
    },
    handleAvatarSuccess (res, file) {
      this.imageUrlName.push(res.result)
      this.fileList.push({
        url: this.qiniu + res.result,
        name: res.result
      })
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.shopDetails.productImg = this.imageUrlName.join()
          ApiCommodity[this.isEdit === 'edit' ? 'updateScoreProduct' : 'addProduct'].call(this, that.shopDetails, res => {
            that.$message({
              message: '保存成功',
              type: 'success'
            })
            that.getList()
            that.$refs[formName].resetFields()
            that.shopDailog = false
            that.fileList = []
            that.imageUrlName = []
          })
        }
      })
    },
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiCommodity.exportProduct.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'ge',
          value: ''
        }, {
          field: 'morningSignState',
          operator: 'eq',
          value: ''
        }]
      }
      this.onSearch()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'productId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      }
      ApiCommodity.getProduct.call(this, data, (listData) => {
        listData.content = listData.content.map(item => {
          if (item.productImg && item.productImg.length > 0) {
            item.productImg = item.productImg.split(',')
            item.productImg = item.productImg.map(list => {
              return {
                url: this.qiniu + list,
                name: list
              }
            })
          }
          return item
        })
        this.tableList = listData.content
        console.log(this.tableList)
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
