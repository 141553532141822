import coursewareQuery from './coursewareQuery/index.vue'
import lessonManagement from './lessonManagement/'
import classManagement from './classManagement/index.vue'
import authority from './authority/index.vue'
import courseManagement from './courseManagement/index.vue'
export default {
  coursewareQuery,
  lessonManagement,
  classManagement,
  authority,
  courseManagement
}
