<template>
  <div style="width:100%;height:100%;display:flex;align-items:center;justify-content:center">
    <img style="width:50%;margin-top:-80px" src="../../../../../static/images/404/authority.png">
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {},
  mounted () {
  }
}
</script>
