import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Message } from 'element-ui'
import settings from './settings.js'
import qs from 'qs'
Vue.use(VueAxios, axios)
axios.defaults.timeout = 20000
axios.defaults.baseURL = settings.gbs.host
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'

// axios.defaults.transformRequest = [function (data) {
//   let ret = ''
//   for (const it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   return ret
// }]
// 然后再修改原型链
Vue.prototype.$axios = axios
/**
 * 封装axios的通用请求
 * @param  {string}   type      get或post
 * @param  {string}   url       请求的接口URL
 * @param  {object}   data      传的参数，没有则传空对象
 * @param  {Function} fn        回调函数
 * @param  {boolean}  tokenFlag 是否需要携带token参数，为true，不需要；false，需要。一般除了登录，都需要
 */
export default function (type, url, data, fn, tokenFlag, errFn) {
  if (data.ajaxType && data.ajaxType === 'idCard') {
    axios.defaults.baseURL = settings.gbs.idCard
  }
  const that = this
  // 分发显示加载样式任务
  that.$store.dispatch('setAjaxLoading', true)
  var datas
  if (type === 'get' || type === 'delete') {
    datas = {
      params: data
    }
  } else {
    datas = data
  }
  axios.interceptors.request.use(
    config => {
      if (!that.$store.state.login.token && config.url !== 'app/rest/school/green/security/loginCourse2' && config.url !== 'app/rest/school/green/security/loginValidateCodeByManager' && config.url !== 'app/rest/school/green/security/resetPassword') {
        that.$store.dispatch('setAjaxLoading', false)
        // that.$message({
        //   showClose: true,
        //   message: '请重新登录',
        //   type: 'error'
        // })
        that.$router.push('/login')
      } else {
        config.headers.common.Authorization = that.$store.state.login.token
      }
      return config
    }, err => {
      that.$store.dispatch('setAjaxLoading', false)
      return Promise.reject(err)
    }
  )
  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    that.$store.dispatch('setAjaxLoading', false)
    return Promise.reject(error)
  })
  let ContentType = 'application/json; charset=UTF-8'
  // if (url === 'app/rest/school/auth/teacher/changeLoginZoneTeacher2') {
  //   ContentType = 'application/x-www-form-urlencoded; charset=UTF-8'
  //   datas = qs.stringify(datas)
  // }
  if (datas.ajaxType === 'form') {
    ContentType = 'application/x-www-form-urlencoded; charset=UTF-8'
    datas = qs.stringify(datas)
  }
  if (datas.down) {
    axios({
      method: 'post',
      url: url,
      params: datas,
      responseType: 'blob',
      timeout: 50000
    }).then((res) => {
      that.$store.dispatch('setAjaxLoading', false)
      that.$nextTick(() => {
        fn(res.data)
      })
    }).then(() => {
      // Message({
      //   message: '请求发生错误，请重试！',
      //   type: 'error'
      // })
      that.$store.dispatch('setAjaxLoading', false)
    })
  } else {
    axios[type](url, datas, { headers: { 'Content-Type': ContentType } }).then((res) => {
      that.$store.dispatch('setAjaxLoading', false)
      that.$nextTick(() => {
        fn(res.data)
      })
    }).catch(function (res) {
      if (res.response) {
        if (errFn && typeof errFn === 'function') {
          Message({
            message: res.response.data.message ? res.response.data.message : res.response.data,
            type: 'error'
          })
          errFn && errFn.call(that, res.response)
        } else {
          Message({
            message: res.response.data.message ? res.response.data.message : res.response.data,
            type: 'error'
          })
        }
      }
    })
  }
}
