import request from '@/config/request.js'
const ApiTeacherAttendance = request.TeacherAttendance
export default {
  name: 'teacher-attendance',
  data () {
    return {
      loadtext: '正在加载中',
      loadtextNum: '',
      search_data: {
        filters: [{
          field: 'zoneTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'teacherSignState',
          operator: 'like',
          value: ''
        }, {
          field: 'signOnType',
          operator: 'like',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      teacherSignState: [
        {
          label: '正常',
          value: 'NORMAL'
        }, {
          label: '异常',
          value: 'ABNORMAL'
        }
      ],
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: ''
      },
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'zoneTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'teacherSignState',
          operator: 'like',
          value: ''
        }, {
          field: 'signOnType',
          operator: 'like',
          value: ''
        }]
      }
      this.onSearch()
    },
    onExport (formName) {
      console.log(this.exportTime)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiTeacherAttendance.exportTeacherAttendance.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    getDetail (row) {
      this.DetailFormVisible = true
      this.dateDetail = row
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {}
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    onLoading () {
      switch (this.loadtextNum.length) {
        case 0:
          this.loadtextNum = '.'
          break
        case 1:
          this.loadtextNum = '..'
          break
        case 2:
          this.loadtextNum = '...'
          break
        default:
          this.loadtextNum = ''
          break
      }
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      // const time = setInterval(this.onLoading, 500)
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'attendDate'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        // for (var s in query) {
        //   if (this.search_data.filters[s] !== undefined) {
        //     this.search_data[s].filters = query[s]
        //     data[s] = query[s]
        //   }
        // }
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiTeacherAttendance.getTeacherAttendanceList.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        // clearInterval(time)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
