import request from '@/config/request.js'
import moment from 'moment'
import Vue from 'vue'
const ApiclassManagement = request.Course.classManagement
export default {
  name: 'course-class-management',
  data () {
    var checkTime = (rule, value, callback) => {
      if (!this.OnlineCourse.startTime || !this.OnlineCourse.endTime) {
        callback(new Error('请选择时间'))
      } else {
        callback()
      }
    }
    var checkTimeAdd = (rule, value, callback) => {
      if (!this.OnlineCourseDetails.valueTime || !this.OnlineCourseDetails.planBeginTime || !this.OnlineCourseDetails.planEndTime) {
        callback(new Error('请选择时间'))
      } else {
        callback()
      }
    }
    var checkTimeEdit = (rule, value, callback) => {
      if (!this.OnlineCourseDetailsEdit.valueTime || !this.OnlineCourseDetailsEdit.planBeginTime || !this.OnlineCourseDetailsEdit.planEndTime) {
        callback(new Error('请选择时间'))
      } else {
        callback()
      }
    }
    return {
      max: 0,
      min: 0,
      speack: '',
      search_data: {
        filters: [{
          field: 'className',
          operator: 'like',
          value: ''
        }, {
          field: 'zoneTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'assistTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'roomType',
          operator: 'eq',
          value: ''
        }]
      },
      student_search_data: {
        filters: [{
          field: 'student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'student.grade',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      // 需要给分页组件传的信息
      coursePaginations: {
        current_page: 1,
        total: 0,
        page_size: 5,
        layout: 'total, prev, pager, next'
      },
      // 需要给分页组件传的信息
      studentPaginations: {
        current_page: 1,
        total: 0,
        page_size: 5,
        layout: 'total, prev, pager, next'
      },
      // 需要给分页组件传的信息
      kejiePaginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        layout: 'total, prev, pager, next'
      },
      tableList: [],
      position: [
        {
          name: '互动课堂-小班课',
          value: 'SMALL_CLASS'
        },
        {
          name: '直播教学-小班课',
          value: 'SMALL_CLASS_LIVE'
        },
        {
          name: '一对一',
          value: 'ONE_TO_ONE'
        },
        {
          name: '线上作业托',
          value: 'ONLINE_HOMEWORK'
        }
      ],
      grade: [
        {
          name: '一年级',
          value: 'FIRST_GRADE'
        },
        {
          name: '二年级',
          value: 'SECOND_GRADE'
        },
        {
          name: '三年级',
          value: 'THIRD_GRADE'
        },
        {
          name: '四年级',
          value: 'FOURTH_GRADE'
        },
        {
          name: '五年级',
          value: 'FIFTH_GRADE'
        },
        {
          name: '六年级',
          value: 'SIXTH_GRADE'
        },
        {
          name: '幼小',
          value: 'YOUNG_CONNECTION'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      DetailFormVisible: false,
      formLabelWidth: '140px',
      details: [],
      classDetail: [],
      classId: '',
      classDetailsDailog: false,
      courseTable: [],
      addStudentDailog: false,
      // 所有学生数据
      studentTable: [],
      // 列表勾选的所有列
      selectionStudent: [],
      // 默认需要勾选的id列
      selectStudentIds: [],
      // 新增/编辑课节
      edit: 'add',
      // 新增、编辑课节弹窗
      courseDailog: false,
      courseDailogEdit: false,
      OnlineCourseDetails: {
        enabled: true,
        onlineClass: {
          classId: ''
        },
        planBeginTime: '',
        planEndTime: '',
        remark: '',
        roomDesc: '',
        roomName: '',
        roomType: 'SMALL_CLASS',
        zoneTeacher: {},
        // 选择上课时间
        valueTime: '',
        teacherId: ''
      },
      OnlineCourseDetailsEdit: {
        remark: '',
        roomDesc: '',
        roomName: '',
        valueTime: '',
        teacherId: '',
        planBeginTime: '',
        planEndTime: ''
      },
      teacherTable: [],
      courseRules: {
        roomName: [
          { required: true, message: '请输入课节名称', trigger: 'blur' }
        ],
        teacherId: [
          { required: true, message: '请选择授课老师', trigger: 'change' }
        ],
        valueTime: [
          { required: true, validator: checkTimeAdd, trigger: 'change' }
        ]
      },
      courseRulesEdit: {
        roomName: [
          { required: true, message: '请输入课节名称', trigger: 'blur' }
        ],
        teacherId: [
          { required: true, message: '请选择授课老师', trigger: 'change' }
        ],
        valueTime: [
          { required: true, validator: checkTimeEdit, trigger: 'change' }
        ]
      },
      // 添加课件
      addCourseDailog: false,
      CourseTableDetails: [],
      classRooid: '',
      // 选择课件
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      treeTable: [],
      addCourseDailogTree: false,
      treeTableList: [],
      kejian_search_data: {
        filters: [{
          field: 'courseSection.course.courseId',
          operator: 'eq',
          value: ''
        }]
      },
      selectionKejie: [],
      selectionKejieCheck: [],
      // 学生监控
      studentDailog: false,
      studentJiankong: [],
      // 新建线上班级
      addOnLineDailog: false,
      OnlineCourse: {
        assistTeacher: null,
        className: '',
        courseSections: [],
        startTime: '',
        endTime: '',
        roomType: '',
        remark: '',
        zoneStudentIds: [],
        zoneTeacher: {},
        sectionType: 'COURSE',
        zoneTeacherid: ''
      },
      roomType: [
        {
          name: '小班课(直播互动)',
          value: 'SMALL_CLASS'
        },
        {
          name: '小班课(直播教学)',
          value: 'SMALL_CLASS_LIVE'
        },
        {
          name: '一对一',
          value: 'ONE_TO_ONE'
        },
        {
          name: '线上作业托',
          value: 'ONLINE_HOMEWORK'
        }
      ],
      classPeople: {
        YOUNG_CONNECTION: {
          select: 0,
          count: 0
        },
        FIRST_GRADE: {
          select: 0,
          count: 0
        },
        SECOND_GRADE: {
          select: 0,
          count: 0
        },
        THIRD_GRADE: {
          select: 0,
          count: 0
        },
        FOURTH_GRADE: {
          select: 0,
          count: 0
        },
        FIFTH_GRADE: {
          select: 0,
          count: 0
        },
        SIXTH_GRADE: {
          select: 0,
          count: 0
        },
        OTHER: {
          select: 0,
          count: 0
        }
      },
      studentClassification: {
        YOUNG_CONNECTION: [],
        FIRST_GRADE: [],
        SECOND_GRADE: [],
        THIRD_GRADE: [],
        FOURTH_GRADE: [],
        FIFTH_GRADE: [],
        SIXTH_GRADE: [],
        OTHER: []
      },
      // 记录tab是否点击过
      classPeopleNumber: {
        YOUNG_CONNECTION: {
          select: 0
        },
        FIRST_GRADE: {
          select: 0
        },
        SECOND_GRADE: {
          select: 0
        },
        THIRD_GRADE: {
          select: 0
        },
        FOURTH_GRADE: {
          select: 0
        },
        FIFTH_GRADE: {
          select: 0
        },
        SIXTH_GRADE: {
          select: 0
        },
        OTHER: {
          select: 0
        }
      },
      tabsName: '',
      courseMubanDailog: false,
      mubantreeTable: [],
      mubantreeTableList: [],
      mubanselectionKejieCheck: [],
      mobankejian_search_data: {
        filters: [{
          field: 'courseCategory.categoryId',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      mobankejiePaginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        layout: 'total, prev, pager, next'
      },
      OnLineRules: {
        className: [
          { required: true, message: '请输入班级名称', trigger: 'blur' }
        ],
        valueTime: [
          { required: true, validator: checkTime, trigger: 'change' }
        ],
        roomType: [
          { required: true, message: '请选择班级类型', trigger: 'change' }
        ],
        zoneTeacherid: [
          { required: true, message: '请选择授课老师', trigger: 'change' }
        ],
        zoneStudentIds: [
          { required: true, message: '请选择学生', trigger: 'change' }
        ],
        courseSections: [
          { required: true, message: '请添加课节', trigger: 'change' }
        ],
        sectionName: [
          { required: true, message: '请输入课节名称', trigger: 'blur' }
        ],
        sectionDate: [
          { required: true, message: '请选择上课日期', trigger: 'change' }
        ]
      },
      courseId: '',
      courseName: ''
    }
  },
  watch: {
    'OnlineCourse.roomType': function (str) {
      this.OnlineCourse.zoneStudentIds = []
      switch (str) {
        case 'SMALL_CLASS':
          Vue.set(this, 'max', 10)
          Vue.set(this, 'min', 2)
          this.speack = '小班课(直播互动)：学生人数限制为2~10人，按2.8元/小时/人收费'
          break
        case 'SMALL_CLASS_LIVE':
          this.max = 16
          this.min = 2
          this.speack = '小班课(直播教学)：学生人数限制为2~16人，按1.8元/小时/人收费'
          break
        case 'ONE_TO_ONE':
          this.max = 1
          this.min = 1
          this.speack = '一对一：学生人数限制为1人，按1.8元/小时/人收费'
          break
        case 'ONLINE_HOMEWORK':
          this.max = 25
          this.min = 2
          this.speack = '线上作业辅导：学生人数限制为2~25人，按1.8元/小时/人收费'
          break
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    },
    'OnlineCourse.zoneStudentIds': function () {

    }
  },
  methods: {
    onChangeCourser () {
      const that = this
      if (that.courseId === '') {
        that.$message({
          message: '请选择课程',
          type: 'error'
        })
        return
      }
      that.OnlineCourse.courseSections = []
      ApiclassManagement.getCourseById.call(this, { courseId: that.courseId }, (item) => {
        that.courseName = item.courseName
        ApiclassManagement.getCourseSectionsByCourseId.call(this, { courseId: that.courseId }, (data) => {
          that.courseMubanDailog = false
          data.map(list => {
            that.OnlineCourse.courseSections.push({
              sectionId: list.sectionId,
              sectionName: list.sectionName,
              sectionDate: ''
            })
          })
        })
      })
    },
    getsectionType () {
      this.OnlineCourse.courseSections = []
    },
    addOnlineCourse (formName) {
      console.log(this.OnlineCourse)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.teacherTable.map(item => {
            if (this.OnlineCourse.zoneTeacherid === item.teacherId) {
              this.OnlineCourse.zoneTeacher = item
            }
          })
          delete this.OnlineCourse.zoneTeacherid
          delete this.OnlineCourse.sectionType
          this.OnlineCourse.startTime = moment().format('YYYY-MM-DD') + ' ' + this.OnlineCourse.startTime + ':' + moment().format('ss')
          this.OnlineCourse.endTime = moment().format('YYYY-MM-DD') + ' ' + this.OnlineCourse.endTime + ':' + moment().format('ss')
          ApiclassManagement.addOnlineClass.call(this, this.OnlineCourse, () => {
            this.getList()
            this.addOnLineDailog = false
            this.OnlineCourse = {
              assistTeacher: null,
              className: '',
              courseSections: [],
              startTime: '',
              endTime: '',
              roomType: '',
              remark: '',
              zoneStudentIds: [],
              zoneTeacher: {},
              sectionType: 'COURSE',
              zoneTeacherid: ''
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    mobankejieOnChangePageSize (pageSize) {
      this.getMobanKejianList({
        pageSize
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    mobankejieOnChangeCurrentPage (page) {
      this.getMobanKejianList({
        page
      })
    },
    getMobanKejianList ({
      page,
      pageSize,
      fn
    } = {}) {
      this.selectionKejie = []
      this.mobankejiePaginations.current_page = page || 1
      this.mobankejiePaginations.page_size = pageSize || this.mobankejiePaginations.page_size
      var data = {
        offset: (this.mobankejiePaginations.current_page - 1) * this.mobankejiePaginations.page_size,
        limit: this.mobankejiePaginations.page_size,
        data: null,
        filters: this.mobankejian_search_data.filters,
        orFilters: null,
        order: null,
        sort: null
      }
      ApiclassManagement.pageCourse.call(this, data, (listData) => {
        this.mubantreeTableList = listData.content
        this.mobankejiePaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    mubankejieSelectionChange (val) {
      if (val.length >= 0) {
        this.mubantreeTableList.map(item => {
          if (item === val[val.length - 1]) {
            this.$refs.mubankejianMultipleTable.toggleRowSelection(val[val.length - 1], true)
          } else {
            this.$refs.mubankejianMultipleTable.toggleRowSelection(item, false)
          }
        })
        this.courseId = val[val.length - 1].courseId
      }
    },
    getMubanKejie (data) {
      if (data.layerCode) {
        this.mobankejian_search_data.filters[0].value = data.key
        this.getMobanKejianList()
      }
    },
    onMubanLoadTree (node, resolve) {
      if (node.level === 0) {
        return resolve(this.mubantreeTable)
      }
      if (node.level === 1) {
        for (const index in this.mubantreeTable) {
          if (node.data === this.mubantreeTable[index]) {
            resolve(this.mubantreeTable[index].children)
          }
        }
      } else {
        if (node.data.isLeaf) {
          resolve([])
        } else {
          if (node.data.type) {
            ApiclassManagement.getChildChooseCourseTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else {
            resolve([])
          }
        }
      }
    },
    getCourseTree () {
      ApiclassManagement.getChooseCourseTree.call(this, {}, (data) => {
        this.mubantreeTable = data
        this.courseMubanDailog = true
      })
    },
    // 删除课节
    delCourseItem (index) {
      this.OnlineCourse.courseSections.splice(index, 1)
    },
    // 添加课节
    addCourseItem () {
      this.OnlineCourse.courseSections.push({
        sectionDate: '',
        sectionId: null,
        sectionName: ''
      })
    },
    // 勾选学生
    getStudentCheckBox (val) {
      this.classPeople[this.tabsName].select = 0
      for (const i in val) {
        for (const item in this.studentClassification[this.tabsName]) {
          if (val[i] === this.studentClassification[this.tabsName][item].studentId) {
            this.classPeople[this.tabsName].select += 1
          }
        }
      }
    },
    // 切换tabs 获取某年级所有学生
    getStudentNumber (tab, event) {
      this.tabsName = tab.name
      switch (tab.name) {
        case 'YOUNG_CONNECTION':
          this.classPeopleNumber.YOUNG_CONNECTION.select += 1
          break
        case 'FIRST_GRADE':
          this.classPeopleNumber.FIRST_GRADE.select += 1
          break
        case 'SECOND_GRADE':
          this.classPeopleNumber.SECOND_GRADE.select += 1
          break
        case 'THIRD_GRADE':
          this.classPeopleNumber.THIRD_GRADE.select += 1
          break
        case 'FOURTH_GRADE':
          this.classPeopleNumber.FOURTH_GRADE.select += 1
          break
        case 'FIFTH_GRADE':
          this.classPeopleNumber.FIFTH_GRADE.select += 1
          break
        case 'SIXTH_GRADE':
          this.classPeopleNumber.SIXTH_GRADE.select += 1
          break
        case 'OTHER':
          this.classPeopleNumber.OTHER.select += 1
          break
      }
      if (this.classPeopleNumber[tab.name].select === 1) {
        ApiclassManagement.getZoneStudentListByGrade.call(this, { grade: tab.name }, (data) => {
          this.studentClassification[tab.name] = data
        })
      }
    },
    // 新建线上班级弹窗
    addClassDialog () {
      // 获取授课老师
      this.getZoneTeacherList()
      // 获取相应学生人数
      this.countZoneStudentByGrade()
      this.$nextTick(() => {
        this.addOnLineDailog = true
      })
    },
    countZoneStudentByGrade () {
      ApiclassManagement.countZoneStudentByGrade.call(this, {}, (data) => {
        for (const item in data) {
          switch (data[item][0]) {
            case '幼小':
              this.classPeople.YOUNG_CONNECTION.count = data[item][1]
              break
            case '一年级':
              this.classPeople.FIRST_GRADE.count = data[item][1]
              break
            case '二年级':
              this.classPeople.SECOND_GRADE.count = data[item][1]
              break
            case '三年级':
              this.classPeople.THIRD_GRADE.count = data[item][1]
              break
            case '四年级':
              this.classPeople.FOURTH_GRADE.count = data[item][1]
              break
            case '五年级':
              this.classPeople.FIFTH_GRADE.count = data[item][1]
              break
            case '六年级':
              this.classPeople.SIXTH_GRADE.count = data[item][1]
              break
            case '其他':
              this.classPeople.OTHER.count = data[item][1]
              break
          }
        }
      })
    },
    unique (arr) {
      return Array.from(new Set(arr))
    },
    updateCoursewareForClassroom () {
      const list = []
      for (const item in this.CourseTableDetails) {
        list.push(this.CourseTableDetails[item].courseware)
      }
      for (const index in this.selectionKejieCheck) {
        let bl = false
        for (const item in list) {
          if (list[item].wareId && this.selectionKejieCheck[index].wareId === list[item].wareId) {
            bl = true
          } else {
            if (list[item].courseware && this.selectionKejieCheck[index].wareId === list[item].courseware.wareId) {
              bl = true
            }
          }
        }
        if (!bl) {
          list.push(this.selectionKejieCheck[index])
        }
      }
      const data = {
        roomId: this.classRooid,
        coursewares: list
      }
      ApiclassManagement.updateCoursewareForClassroom.call(this, data, () => {
        this.getCoursetList({ classId: this.classId })
        this.getCourseTable()
        this.treeTableList = []
        this.addCourseDailogTree = false
      })
    },
    kejieSelectionChange (val) {
      this.selectionKejieCheck = val
    },
    getKejie (data) {
      if (data.isLeaf) {
        this.kejian_search_data.filters[0].value = data.layerCode
        this.getKejianList()
      }
    },
    getKejianList ({
      page,
      pageSize,
      fn
    } = {}) {
      this.selectionKejie = []
      this.kejiePaginations.current_page = page || 1
      this.kejiePaginations.page_size = pageSize || this.kejiePaginations.page_size
      var data = {
        offset: (this.kejiePaginations.current_page - 1) * this.kejiePaginations.page_size,
        limit: this.kejiePaginations.page_size,
        data: null,
        filters: this.kejian_search_data.filters,
        orFilters: null,
        order: null,
        sort: null
      }
      ApiclassManagement.pageCourseware.call(this, data, (listData) => {
        const tb = listData.content.map(list => {
          return Object.assign(list, { selection: false })
        })
        if (this.CourseTableDetails && this.CourseTableDetails.length > 0) {
          this.CourseTableDetails.map(item => {
            tb.map(list => {
              if (item.courseware.wareId === list.wareId) {
                list.selection = true
                this.selectionKejie.push(list)
              }
            })
          })
        }
        this.treeTableList = tb
        this.kejiePaginations.total = parseInt(listData.totalElements)
        if (this.selectionKejie.length > 0) {
          this.selectionKejie.forEach(row => {
            this.$nextTick(() => {
              this.$refs.kejianMultipleTable.toggleRowSelection(row, true)
            })
          })
        } else {
          this.$refs.kejianMultipleTable.clearSelection()
        }
        fn && fn()
      })
    },
    onLoadTree (node, resolve) {
      if (node.level === 0) {
        return resolve(this.treeTable)
      }
      if (node.level === 1) {
        for (const index in this.treeTable) {
          if (node.data === this.treeTable[index]) {
            resolve(this.treeTable[index].children)
          }
        }
      } else {
        if (node.data.isLeaf) {
          resolve([])
        } else {
          if (node.data.type === 'ZoneCourse') {
            ApiclassManagement.getAuthorizePublicChildCourseCategoryTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else if (node.data.type === 'CourseCategory' || node.data.type === 'AdminCourseCategory') {
            ApiclassManagement.getChildCourseCategoryTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else if (node.data.type === 'Public') {
            ApiclassManagement.getChildCourseCategoryTreeItems.call(this, { categoryId: node.data.key }, (data) => {
              resolve(data)
            })
          } else {
            resolve([])
          }
        }
      }
    },
    // isLeaf 为true时是最终节点
    getTreeWhile (data) {
      for (const index in data) {
        if (data[index].children) {
          this.getTreeWhile(data[index].children)
        } else {
          if (!data[index].isLeaf) {
            data[index] = Object.assign(data[index], { children: [] })
          }
        }
      }
    },
    addCourseTreeShow () {
      const that = this
      this.treeTableList = []
      this.selectionKejieCheck = []
      ApiclassManagement.getRootCourseCategoryTree.call(this, {}, (data) => {
        // this.getTreeWhile(data)
        that.treeTable = data
        that.addCourseDailogTree = true
      })
    },
    removeCoursewareForClassroom (id) {
      const that = this
      this.$confirm('确认移除该课件吗?移除后该课件不会出现在本次课堂中，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiclassManagement.removeCoursewareForClassroom.call(this, { roomId: this.classRooid, wareId: id, ajaxType: 'form' }, () => {
          that.$message({
            message: '操作成功',
            type: 'success'
          })
          that.getCourseTable()
        })
      }).catch(() => {
      })
    },
    getCourseTable (id) {
      const that = this
      if (id) {
        this.classRooid = id
      } else {
        id = this.classRooid
      }

      ApiclassManagement.getCoursewaresByRoomId.call(this, { roomId: id }, (data) => {
        that.CourseTableDetails = data
        that.addCourseDailog = true
      })
    },
    getStudentTable (id) {
      const that = this
      if (id) {
        this.classRooid = id
      } else {
        id = this.classRooid
      }
      ApiclassManagement.webStudentClassroomsByRoomId.call(this, { roomId: id }, (data) => {
        that.studentJiankong = data
        that.studentDailog = true
      })
    },
    onStop (row) {
      const that = this
      this.$confirm('确定' + (row.enabled ? '停用' : '启动') + '该课节。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiclassManagement.enableClassroom.call(this, { classroomId: row.roomId, enabled: !row.enabled, ajaxType: 'form' }, () => {
          that.getCoursetList({ classId: that.classId })
        })
      }).catch(() => {
      })
    },
    removeClassroomById (id) {
      const that = this
      this.$confirm('是否确定删除该课节？删除后不可恢复。是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiclassManagement.removeClassroomById.call(this, { classroomId: id }, () => {
          that.getCoursetList({ classId: that.classId })
        })
      }).catch(() => {
      })
    },
    onParseDate ({ date, outputFormat, inputFormat }) {
      outputFormat = outputFormat !== undefined ? outputFormat : 'YYYY-MM-DD hh:mm:ss'
      return inputFormat ? moment(date, inputFormat).format(outputFormat) : moment(date).format(outputFormat)
    },
    editCourse (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.teacherTable.map(item => {
            if (this.OnlineCourseDetailsEdit.teacherId === item.teacherId) {
              this.OnlineCourseDetailsEdit.zoneTeacher = item
            }
          })
          this.OnlineCourseDetailsEdit.planBeginTime = this.OnlineCourseDetailsEdit.valueTime + ' ' + this.OnlineCourseDetailsEdit.planBeginTime + ':' + moment().format('ss')
          this.OnlineCourseDetailsEdit.planEndTime = this.OnlineCourseDetailsEdit.valueTime + ' ' + this.OnlineCourseDetailsEdit.planEndTime + ':' + moment().format('ss')
          ApiclassManagement.updateOnlineClassroom.call(this, this.OnlineCourseDetailsEdit, () => {
            this.courseDailogEdit = false
            this.getDetail()
          })
        } else {
          console.log('error submit!!')
        }
      })
    },
    addCourse (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.teacherTable.map(item => {
            if (this.OnlineCourseDetails.teacherId === item.teacherId) {
              this.OnlineCourseDetails.zoneTeacher = item
            }
          })
          this.courseDailog = false
          this.OnlineCourseDetails.planBeginTime = this.OnlineCourseDetails.valueTime + ' ' + this.OnlineCourseDetails.planBeginTime + ':' + moment().format('ss')
          this.OnlineCourseDetails.planEndTime = this.OnlineCourseDetails.valueTime + ' ' + this.OnlineCourseDetails.planEndTime + ':' + moment().format('ss')
          this.OnlineCourseDetails.onlineClass.classId = this.classId
          ApiclassManagement.addOnlineClassroom.call(this, this.OnlineCourseDetails, () => {
            this.getDetail()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    addCourseShow () {
      this.OnlineCourseDetails = {
        enabled: true,
        onlineClass: {
          classId: ''
        },
        planBeginTime: '',
        planEndTime: '',
        remark: '',
        roomDesc: '',
        roomName: '',
        roomType: this.OnlineCourseDetails.roomType,
        zoneTeacher: {},
        // 选择上课时间
        valueTime: '',
        teacherId: ''
      }
      this.getZoneTeacherList(() => {
        this.courseDailog = true
      })
    },
    editCourseShow (row) {
      if (row && !row.live && !row.beginTime && !row.endTime) {
        this.getZoneTeacherList(() => {
          this.OnlineCourseDetailsEdit = Object.assign(this.OnlineCourseDetailsEdit, row)
          const valueTime = moment(row.planEndTime).format('YYYY-MM-DD')
          // valueTime.push(moment(row.planBeginTime).format())
          // valueTime.push(moment(row.planEndTime).format())
          this.OnlineCourseDetailsEdit.valueTime = valueTime
          this.OnlineCourseDetailsEdit.teacherId = row.zoneTeacher.teacherId
          this.OnlineCourseDetailsEdit.roomName = row.roomName ? row.roomName : ''
          this.OnlineCourseDetailsEdit.roomDesc = row.roomDesc ? row.roomDesc : ''
          this.OnlineCourseDetailsEdit.remark = row.remark ? row.remark : ''
          this.OnlineCourseDetailsEdit.planBeginTime = moment(row.planBeginTime).format('HH:mm')
          this.OnlineCourseDetailsEdit.planEndTime = moment(row.planEndTime).format('HH:mm')
          this.courseDailogEdit = true
        })
      } else {
        this.$message({
          message: '该课节已使用，无法编辑！',
          type: 'error'
        })
      }
    },
    getZoneTeacherList (fn) {
      ApiclassManagement.getZoneTeacherList.call(this, {}, (data) => {
        this.teacherTable = data
        fn && fn()
      })
    },
    addStudentSave () {
      this.$confirm('是否确认添加班级学生即将添加班级学生(仅对未开课的课节生效),是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const ids = []
        this.selectionStudent.filter(item => {
          ids.push(item.studentId)
        })
        const data = {
          classId: this.classId,
          zoneStudentIds: ids
        }
        ApiclassManagement.changeOnlineClassStudents.call(this, data, () => {
          this.getDetail()
          this.addStudentDailog = false
        })
      }).catch(() => {
      })
    },
    getSelectStatus (row, index) {
      if (row.selection === true) {
        return false
      } else {
        return true
      }
    },
    handleSelectionChange (val) {
      this.selectionStudent = val
    },
    resetStudentTable () {
      this.student_search_data = {
        filters: [{
          field: 'student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'student.grade',
          operator: 'eq',
          value: ''
        }]
      }
      this.onStudentSearch()
    },
    onStudentSearch () {
      var where = []
      for (var s in this.student_search_data.filters) {
        if (this.student_search_data.filters[s].value) {
          where.push(this.student_search_data.filters[s])
        }
      }
      this.getStudent({
        where
      })
    },
    getStudent ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      this.studentPaginations.current_page = page || 1
      this.studentPaginations.page_size = pageSize || this.studentPaginations.page_size
      var data = {
        offset: (this.studentPaginations.current_page - 1) * this.studentPaginations.page_size,
        limit: this.studentPaginations.page_size,
        data: {
          studentCondition: 'ALL'
        },
        filters: null,
        orFilters: null,
        order: null,
        sort: null
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      }
      ApiclassManagement.webZonePageZoneStudent.call(this, data, (listData) => {
        const tb = listData.content.map(list => {
          return Object.assign(list, { selection: false })
        })
        if (this.classDetail && this.classDetail.studentOnlineClasses.length > 0) {
          this.classDetail.studentOnlineClasses.map(item => {
            tb.map(list => {
              if (item.zoneStudent.studentId === list.studentId) {
                this.selectStudentIds.push(list)
                list.selection = true
              }
            })
          })
        }
        this.studentTable = tb
        this.studentPaginations.total = parseInt(listData.totalElements)
        this.$nextTick(() => {
          if (this.selectStudentIds.length > 0) {
            this.selectStudentIds.forEach(row => {
              this.$refs.studentMultipleTable.toggleRowSelection(row, true)
            })
          } else {
            this.$refs.studentMultipleTable.clearSelection()
          }
        })
        fn && fn()
      })
    },
    addStudentShow () {
      this.selectStudentIds = []
      this.addStudentDailog = true
      this.getStudent({
      })
    },
    removeStudentForOnlineClass (row) {
      this.$confirm('确认删除该学生吗?删除数据不可恢复，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          studentId: row.zoneStudent.studentId,
          classId: this.classId
        }
        ApiclassManagement.removeStudentForOnlineClass.call(this, data, () => {
          this.getDetail()
        })
      }).catch(() => {
      })
    },
    onDelete (row) {
      this.$confirm('确认删除该班级吗?删除数据不可恢复，是否继续', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          classId: row.classId
        }
        ApiclassManagement.removeOnlineClassById.call(this, data, () => {
          this.getList()
        })
      }).catch(() => {
      })
    },
    getDetail (row) {
      const that = this
      this.classId = row ? row.classId : this.classId
      if (row) {
        this.OnlineCourseDetails.roomType = row.roomType
      }
      ApiclassManagement.getOnlineClassByIdFetchData.call(this, { classId: this.classId }, (listData) => {
        that.classDetail = listData
        that.classDetailsDailog = true
        that.getCoursetList({
          classId: this.classId,
          fn: () => {
            that.$nextTick(() => {
              that.classDetailsDailog = true
            })
          }
        })
      })
    },
    getCoursetList ({
      page,
      pageSize,
      fn,
      classId
    } = {}) {
      this.coursePaginations.current_page = page || 1
      this.coursePaginations.page_size = pageSize || this.coursePaginations.page_size
      var data = {
        offset: (this.coursePaginations.current_page - 1) * this.coursePaginations.page_size,
        limit: this.coursePaginations.page_size,
        data: null,
        filters: [{
          field: 'onlineClass.classId', operator: 'eq', value: classId
        }],
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      ApiclassManagement.pageClassroom.call(this, data, (listData) => {
        this.courseTable = listData.content
        this.coursePaginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getRoomType (type) {
      let str = ''
      switch (type) {
        case 'SMALL_CLASS':
          str = '互动课堂-小班课'
          break
        case 'SMALL_CLASS_LIVE':
          str = '直播教学-小班课'
          break
        case 'ONE_TO_ONE':
          str = '一对一'
          break
        case 'ONLINE_HOMEWORK':
          str = '线上作业托'
          break
        default:
          str = ''
      }
      return str
    },
    getDetails (row) {
      this.details = row
      this.DetailFormVisible = true
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'className',
          operator: 'like',
          value: ''
        }, {
          field: 'zoneTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'assistTeacher.teacher.teacherName',
          operator: 'like',
          value: ''
        }, {
          field: 'roomType',
          operator: 'eq',
          value: ''
        }]
      }
      this.onSearch()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      // var sd = {}
      // var query = this.$route.query
      // for (var p in query) {
      //   sd[p] = query[p]
      // }
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    studentOnChangePageSize (pageSize) {
      this.getStudent({
        pageSize
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    studentOnChangeCurrentPage (page) {
      this.getStudent({
        page
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    kejieOnChangePageSize (pageSize) {
      this.getKejianList({
        pageSize
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    kejieOnChangeCurrentPage (page) {
      this.getKejianList({
        page
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    courseOnChangeCurrentPage (page) {
      this.getCoursetList({
        page,
        classId: this.classId
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    courseOnChangePageSize (pageSize) {
      this.getCoursetList({
        pageSize,
        classId: this.classId
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiclassManagement.pageOnlineClass.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
