import request from '@/config/request.js'
import setting from '@/config/settings'
import pdf from 'vue-pdf'
const ApiFile = request.File
export default {
  name: 'admissions',
  components: {
    pdf
  },
  data () {
    return {
      qiniu: setting.gbs.qiniu,
      baseFolderPath: setting.gbs.folderPath,
      detailsList: [],
      packId: '',
      tagNames: [],
      semesterNames: [],
      rootFolders: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      fileTable: [],
      filePath: '/',
      baseCode: '',
      baseTime: '',
      imgUrl: '',
      imgView: false,
      pdfUrl: '',
      pdfView: false,
      pageNum: 1,
      pageTotalNum: 1,
      loadedRatio: 0
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 390
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    conver (limit) {
      var size = ''
      if (limit < 0.1 * 1024) {
        // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B'
      } else if (limit < 0.1 * 1024 * 1024) {
        // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB'
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
      } else {
        // 其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
      }

      var sizestr = size + ''
      var len = sizestr.indexOf('.')
      var dec = sizestr.substr(len + 1, 2)
      if (dec === '00') {
        // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
      }
      return sizestr
    },
    // 上一页
    prePage () {
      let page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页
    nextPage () {
      let page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    isAssetTypeAnImage (ext) {
      return [
        'png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1
    },
    getFileType (filePath, type) {
      if (type) {
        return 'fload'
      } else {
        var index = filePath.lastIndexOf('.')
        var ext = filePath.substr(index + 1)
        return ext
      }
    },
    onChangeTree (data) {
      this.filePath = data.key
      this.getFileItems()
    },
    getFilePackById () {
      ApiFile.getFilePackById.call(this, { packId: this.$route.params.packId }, (listData) => {
        this.detailsList = listData
        this.tagNames = listData.tagNames ? listData.tagNames.split(',') : []
        this.semesterNames = listData.semesterNames ? listData.semesterNames.split(',') : []
        this.baseCode = listData.fileBase.baseCode
        this.baseTime = this.getCreateTime(listData.createTime)
      })
    },
    getCreateTime (time) {
      const newTime = new Date(time)
      const year = newTime.getFullYear()
      const month = this.formatNumber(newTime.getMonth() + 1)
      const day = this.formatNumber(newTime.getDate())
      return year + '' + month + '' + day
    },
    formatNumber (n) {
      const str = n.toString()
      return str[1] ? str : '0' + str
    },
    getRootFolders () {
      ApiFile.getRootFolders.call(this, { packId: this.$route.params.packId }, (listData) => {
        this.rootFolders = listData
      })
    },
    getFileItems () {
      ApiFile.getFileItems.call(this, { packId: this.$route.params.packId, filePath: this.filePath }, (listData) => {
        this.fileTable = listData
      })
    },
    downloadFileAll () {
      ApiFile.downloadFileAll.call(this, { packId: this.$route.params.packId, down: true }, (res) => {
        const blob = new Blob([res])
        const objectUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        a.setAttribute('href', objectUrl)
        a.setAttribute('download', this.detailsList.packName + '.zip')
        a.click()
        URL.revokeObjectURL(objectUrl)
      })
    },
    getView (row) {
      if (row.directory) {
        this.filePath = row.filePath
        this.getFileItems()
      } else {
        if (/\.(doc|docx|ppt|pptx|xls|xlsx)$/.test(row.filePath)) {
          const url = '/' + this.baseCode + '/' + this.baseTime + '/' + this.$route.params.packId + decodeURI(encodeURI(row.filePath))
          window.open(
            'https://view.officeapps.live.com/op/view.aspx?src=' + this.baseFolderPath + url, '_blank'
          )
          return false
        } else if (/\.(jpg|jpge|png)$/.test(row.filePath)) {
          const url = this.baseFolderPath + '/' + this.baseCode + '/' + this.baseTime + '/' + this.$route.params.packId + decodeURI(encodeURI(row.filePath))
          this.imgUrl = url
          this.imgView = true
        } else if (/\.(pdf)$/.test(row.filePath)) {
          const url = this.baseFolderPath + '/' + this.baseCode + '/' + this.baseTime + '/' + this.$route.params.packId + decodeURI(encodeURI(row.filePath))
          this.pdfUrl = url
          this.pdfView = true
        }
      }
    },
    downloadFile (item) {
      if (item.directory) {
        this.filePath = item.filePath
        this.getFileItems()
      } else {
        ApiFile.downloadFile.call(this, { packId: this.$route.params.packId, filePath: item.filePath, down: true }, (res) => {
          const blob = new Blob([res])
          const objectUrl = URL.createObjectURL(blob)
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.setAttribute('style', 'display:none')
          a.setAttribute('href', objectUrl)
          a.setAttribute('download', item.name)
          a.click()
          URL.revokeObjectURL(objectUrl)
        })
      }
    }
  },
  mounted () {
    this.getFilePackById()
    this.getRootFolders()
    this.getFileItems()
  }
}
