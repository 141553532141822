import request from '@/config/request.js'
import tools from '@/util/tools'
import common from '@/components/common/'
const ApiUserRole = request.UserRole
const basic = tools.basic
export default {
  name: 'system-role',
  components: common,
  data () {
    return {
      search_data: {
        searchKey: '',
        searchValue: ''
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      options: [{
        type: 'name',
        value: '角色名称'
      }],
      roleDialog: false,
      roleType: 'edit',
      roleInfo: {
        id: '',
        name: ''
      },
      formLabelWidth: '120px',
      roleRules: {
        name: [
          { required: true, message: '请输入角色名', trigger: 'blur' }
        ]
      },
      roleTable: [],
      roleList: [],
      editRoleDialog: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      defaultCheck: [],
      roleid: '',
      roleAutoInfo: {
        ids: '',
        select: ''
      },
      dataRoleDialog: false,
      dataRoleList: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 305
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    /**
     * 保存数据权限
     */
    onDataRoleSave () {
      const that = this
      ApiUserRole.saveDataAuth.call(this, that.roleAutoInfo, (res) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.dataRoleDialog = false
      })
    },
    /**
     * 取消/关闭弹窗
     * */
    onDataRoleCancel (formName) {
      this.$refs[formName].resetFields()
      this.roleInfo = {
        id: '',
        name: ''
      }
      this.dataRoleDialog = false
    },
    /**
     * 组件更改值
     * */
    onSetValue (values) {
      this.roleAutoInfo.select = values.join(',')
    },
    /**
     * 添加或编辑弹窗控制
     * */
    onDataRoleDialog (id) {
      this.roleAutoInfo.ids = id
      ApiUserRole.getDataAuth.call(this, {}, (res) => {
        this.dataRoleList = res.data.map(item => {
          return {
            name: item + ''
          }
        })
        ApiUserRole.getDataAuth.call(this, { roleId: id }, (res) => {
          this.roleAutoInfo.select = res.data.join(',')
          this.dataRoleDialog = true
        })
      })
    },
    /**
     * 取消权限弹窗
     * */
    onCancelRole () {
      this.roleList = []
      this.defaultCheck = []
      this.editRoleDialog = false
    },
    /**
     * 递归获取权限
     * */
    getRoleDetail (roleList, item) {
      if (!item.menuParent) {
        roleList.push({
          label: item.name,
          id: item.id,
          check: item.checked
        })
      } else {
        for (const key in roleList) {
          if (item.menuParent === roleList[key].id) {
            if (roleList[key].children) {
              roleList[key].children.push({
                label: item.name,
                id: item.id,
                check: item.checked
              })
            } else {
              roleList[key].children = [{
                label: item.name,
                id: item.id,
                check: item.checked
              }]
            }
            continue
          } else {
            if (roleList[key].children) {
              this.getRoleDetail(roleList[key].children, item)
            }
          }
        }
      }
    },
    /**
     * 查看是否当前节点下面是否有子节点
     * */
    checkRules (item, data) {
      let bl = false
      for (const key in data) {
        if (data[key].menuParent && data[key].menuParent === item.id) {
          // 表示有子节点，返回true
          bl = true
          break
        }
      }
      return bl
    },
    /**
     * 获取当前角色权限
     * */
    onShowRole (roleId) {
      const that = this
      that.roleid = roleId
      ApiUserRole.getRoleList.call(this, { roleId: roleId }, res => {
        that.roleTable = res.data
        for (const key in that.roleTable) {
          for (const i in that.roleTable) {
            if (that.roleTable[key].menuLevel < that.roleTable[i].menuLevel) {
              let arr = []
              arr = that.roleTable[key]
              that.roleTable[key] = that.roleTable[i]
              that.roleTable[i] = arr
            }
          }
        }
        res.data.forEach(item => {
          if (item.checked) {
            if (!that.checkRules(item, res.data)) {
              that.defaultCheck.push(item.id)
            }
          }
          that.getRoleDetail(that.roleList, item)
        })
        that.editRoleDialog = true
        that.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(that.defaultCheck)
        })
      })
    },
    /**
     * 获取子节点如果勾选了某项，其父节点也相应获取
     * */
    getParentRole (roleList) {
      for (const key in roleList) {
        // 循环权限数据集，找到当前id对应的menuParent
        for (const item in this.roleTable) {
          if (roleList[key] === this.roleTable[item].id) {
            // 如果当前不是第一级菜单，则通过menuParent 找到当前父节点。如果是第一级菜单，则不管
            const menuParent = this.roleTable[item].menuParent
            for (const i in this.roleTable) {
              // 根据当前tree给出的ID 找到当前父节点的id
              if (menuParent === this.roleTable[i].id) {
                // 再判断父节点是否存在当前roleList里面，如果不存在，则添当前父节点
                if (roleList.indexOf(menuParent) < 0) {
                  roleList.push(menuParent)
                  this.getParentRole(roleList)
                }
              }
            }
          }
        }
      }
    },
    /**
     * 保存功能权限
     * */
    onSaveRole () {
      const that = this
      const roleList = this.$refs.tree.getCheckedKeys()
      this.getParentRole(roleList)
      ApiUserRole.saveRoleAuth.call(this, { menuIds: roleList, roleId: that.roleid }, data => {
        that.$message({
          message: '保存成功',
          type: 'success'
        })
        this.editRoleDialog = false
        that.getList()
      })
    },
    /**
     * 保存
     * */
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUserRole.onSave.call(this, that.roleInfo, data => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            that.onCancel(formName)
            that.getList()
          })
        }
      })
    },
    /**
     * 取消/关闭弹窗
     * */
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.roleInfo = {
        id: '',
        name: ''
      }
      this.roleDialog = false
    },
    /**
     * 添加或编辑弹窗控制
     * */
    onShowDialog (row) {
      if (row === 'add') {
        this.roleType = 'add'
      } else {
        this.roleType = 'edit'
        this.roleInfo = basic.partData(row, [
          'id',
          'name'
        ])
      }
      this.roleDialog = true
    },
    /**
     * 搜索事件
     */
    onSearch () {
      var sd = {}
      var query = this.$route.query
      for (var p in query) {
        sd[p] = query[p]
      }
      var where = {}
      for (var s in this.search_data) {
        if (this.search_data[s]) {
          where[s] = this.search_data[s]
        } else {
          if (sd[s]) {
            delete sd[s]
          }
        }
      }
      this.getList({
        where,
        fn: () => {
          this.setPath(Object.assign(sd, where))
        }
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    /**
     * 重置查询文本框
     */
    resetForm () {
      this.$data.search_data = {
        searchKey: '',
        searchValue: ''
      }
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        page: this.paginations.current_page,
        limit: this.paginations.page_size
      }
      if (where) {
        data = Object.assign(data, where || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiUserRole.getUserRoleList.call(this, data, (listData) => {
        this.tableList = listData.data
        this.paginations.total = parseInt(listData.count)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
