import basic from './basic'
import commonData from './common_data'
import commonMethods from './common_methods'
import validate from './validate'
export default {
  basic,
  commonData,
  commonMethods,
  validate
}
