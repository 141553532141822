import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiTeacherManagement = request.TeacherManagement
export default {
  name: 'teacher-management',
  data () {
    var checkidCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入身份证号码'))
      }
      setTimeout(() => {
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的身份证号码'))
        } else {
          callback()
          // console.log(this)
          // ApiTeacherManagement.getTeacherIdCar.call(this, {
          //   id: this.$data.dateDetailEdit.teacher.idCard,
          //   name: this.$data.dateDetailEdit.teacher.teacherName
          // }, res => {
          //   console.log(res, 'res')
          //   callback()
          // })
          // if (reg.test(value) === false) {
          //   callback(new Error('请输入正确的身份证号码'))
          // } else {
          //   callback()
          // }
        }
      }, 1000)
    }
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入老师邮箱'))
      }
      setTimeout(() => {
        var reg = /(^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$)/
        if (reg.test(value) === false) {
          callback(new Error('请输入正确的老师邮箱'))
        } else {
          callback()
        }
      }, 1000)
    }
    return {
      edit: '',
      qiniu: setting.gbs.qiniu,
      search_data: {
        filters: [{
          field: 'teacher.mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'position',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      DetailFormVisible: false,
      DetailFormVisibleEdit: false,
      dateDetail: {},
      dateDetailEdit: {
        teacherId: '',
        checked: false,
        disable: false,
        // 职务
        position: '',
        // 昵称
        nickname: '',
        // 教师邮箱
        teacherEmail: '',
        // 入职日期
        entryDate: '',
        teacher: {
          accountId: '',
          // 老师姓名
          teacherName: '',
          // 头像
          avatarImg: '',
          // 手机
          mobile: '',
          // 毕业学校
          graduatedSchool: '',
          // 毕业于安亲大学几期
          anqinUniversity: '',
          // 档案号
          archiveCode: '',
          // 婚否
          marriage: '',
          // 性别
          gender: '',
          // 身份证号
          idCard: '',
          // 生日
          birthday: '',
          // 籍贯
          origin: '',
          // 学历
          education: '',
          // 手机型号
          mobileModel: '',
          // 手机厂商
          mobileVendor: ''
        }
      },
      formLabelWidth: '140px',
      type: true,
      teacherType: [
        {
          name: '在职',
          value: true
        },
        {
          name: '离职',
          value: false
        }
      ],
      position: [
        {
          name: '校长',
          value: 'PRINCIPAL'
        },
        {
          name: '教学组长',
          value: 'TEACHING_GROUP_LEADER'
        },
        {
          name: '老师',
          value: 'TEACHER'
        },
        {
          name: '助教',
          value: 'ASSISTANT'
        },
        {
          name: '厨师',
          value: 'CHEF'
        },
        {
          name: '财务',
          value: 'FINANCE'
        },
        {
          name: '其他',
          value: 'OTHERS'
        },
        {
          name: '招生人',
          value: 'ADMISSIONS'
        }
      ],
      education: [
        {
          name: '高中',
          value: 'HIGH'
        },
        {
          name: '大专',
          value: 'JUNIOR_COLLEGE'
        },
        {
          name: '本科',
          value: 'UNIVERSITY'
        },
        {
          name: '硕士',
          value: 'MASTER'
        },
        {
          name: '博士',
          value: 'LEARNED_SCHOLAR'
        },
        {
          name: '其他',
          value: 'OTHER'
        }
      ],
      imageUrl: '',
      myHeaders: { Authorization: this.$store.state.login.token },
      rules: {
        'teacher.teacherName': [
          { required: true, message: '请输入老师姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        nickname: [
          { required: true, message: '请输入老师昵称', trigger: 'blur' }
        ],
        'teacher.mobile': [
          { required: true, message: '请输入老师电话', trigger: 'blur' }
        ],
        position: [
          { required: true, message: '请选择老师职务', trigger: 'change' }
        ],
        teacherEmail: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        'teacher.education': [
          { required: true, message: '请选择学历', trigger: 'change' }
        ],
        entryDate: [
          { required: true, message: '请选择老师入职日期', trigger: 'change' }
        ],
        'teacher.origin': [
          { required: true, message: '请输入籍贯', trigger: 'blur' }
        ],
        'teacher.archiveCode': [
          { required: true, message: '请输入档案号', trigger: 'blur' }
        ],
        'teacher.anqinUniversity': [
          { required: true, message: '请输入毕业安亲大学几期', trigger: 'blur' }
        ],
        'teacher.graduatedSchool': [
          { required: true, message: '请输入毕业学校', trigger: 'blur' }
        ],
        'teacher.idCard': [
          { required: true, validator: checkidCard, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'teacher.mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'position',
          operator: 'eq',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: ''
        }]
      }
      this.onSearch()
    },
    teacherFormClose () {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.teacherForm.resetFields()
          this.DetailFormVisibleEdit = false
        })
        .catch(_ => {})
    },
    onSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dateDetailEdit.teacher.avatarImg = this.imageUrlName
          ApiTeacherManagement[this.dateDetailEdit.teacherId ? 'onTeacherSave' : 'onTeacherAdd'].call(this, this.dateDetailEdit, res => {
            this.$refs.teacherForm.resetFields()
            this.getList()
            this.DetailFormVisibleEdit = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.DetailFormVisibleEdit = false
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = this.qiniu + res.result
      this.imageUrlName = res.result
    },
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    getDetailDelete (row) {
      if (row.del) {
        this.$confirm('确定该老师重新入职, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ApiTeacherManagement.activationZoneTeacher.call(this, row, res => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          })
        })
      } else {
        this.$confirm('确定操作该老师离职, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          ApiTeacherManagement.onTeacherDelete.call(this, { teacherId: row.teacherId }, res => {
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          })
        })
      }
    },
    getDetailEdit (row, type) {
      this.edit = type
      // this.dateDetailEdit = Object.assign(this.dateDetailEdit, row)
      if (type === 'add') {
        this.dateDetailEdit = {
          checked: false,
          disable: false,
          // 职务
          position: '',
          // 昵称
          nickname: '',
          // 教师邮箱
          teacherEmail: '',
          // 入职日期
          entryDate: '',
          teacher: {
            // 老师姓名
            teacherName: '',
            // 头像
            avatarImg: '',
            // 手机
            mobile: '',
            // 毕业学校
            graduatedSchool: '',
            // 毕业于安亲大学几期
            anqinUniversity: '',
            // 档案号
            archiveCode: '',
            // 婚否
            marriage: '',
            // 性别
            gender: '',
            // 身份证号
            idCard: '',
            // 生日
            birthday: '',
            // 籍贯
            origin: '',
            // 学历
            education: '',
            // 手机型号
            mobileModel: '',
            // 手机厂商
            mobileVendor: ''
          }
        }
      } else {
        this.imageUrl = this.qiniu + row.teacher.avatarImg
        this.dateDetailEdit = {
          checked: false,
          disable: false,
          // 职务
          position: row.position,
          // 昵称
          nickname: row.nickname,
          // 教师邮箱
          teacherEmail: row.teacherEmail,
          // 入职日期
          entryDate: row.entryDate,
          teacherId: row.teacherId,
          teacher: {
            accountId: row.teacher.accountId,
            teacherName: row.teacher.teacherName,
            // 头像
            avatarImg: row.teacher.avatarImg,
            // 手机
            mobile: row.teacher.mobile,
            // 毕业学校
            graduatedSchool: row.teacher.graduatedSchool,
            // 毕业于安亲大学几期
            anqinUniversity: row.teacher.anqinUniversity,
            // 档案号
            archiveCode: row.teacher.archiveCode,
            // 婚否
            marriage: row.teacher.marriage,
            // 性别
            gender: row.teacher.gender,
            // 身份证号
            idCard: row.teacher.idCard,
            // 生日
            birthday: row.teacher.birthday,
            // 籍贯
            origin: row.teacher.origin,
            // 学历
            education: row.teacher.education,
            // 手机型号
            mobileModel: row.teacher.mobileModel,
            // 手机厂商
            mobileVendor: row.teacher.mobileVendor
          }
        }
      }
      this.DetailFormVisibleEdit = true
    },
    getDetail (row) {
      ApiTeacherManagement.getStudyClassByTeacherId.call(this, { teacherId: row.teacherId }, (listData) => {
        const str = listData.map(item => {
          return item.studyClassName
        })
        this.dateDetail = row
        this.dateDetail.schools = str
      })
      this.DetailFormVisible = true
    },
    /**
     * 搜索事件
     */
    onSearch () {
      if (this.type) {
        console.log('zaizhi')
        this.search_data.filters[2].value = false
      }
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value !== '') {
          where.push(this.search_data.filters[s])
        }
      }
      console.log('zaizhi1')
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'teacher.accountId'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      if (this.type) {
        ApiTeacherManagement.getTeacherList.call(this, data, (listData) => {
          this.tableList = listData.content
          this.paginations.total = parseInt(listData.totalElements)
          fn && fn()
        })
      } else {
        ApiTeacherManagement.getTeacherQuitList.call(this, data, (listData) => {
          this.tableList = listData.content
          this.paginations.total = parseInt(listData.totalElements)
          fn && fn()
        })
      }
    }
  },
  mounted () {
    this.getList()
  }
}
