import request from '@/config/request.js'
import tools from '@/util/tools/index'
import moment from 'moment'

const ApiStudentAttendance = request.StudentAttendance
const commonData = tools.commonData

export default {
  name: 'student-attendance',
  data () {
    return {
      formLabelWidth: '120px',
      loadtext: '正在加载中',
      loadtextNum: '',
      search_data: {
        filters: [{
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'morningSignState',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      teacherSignState: [
        {
          label: '正常',
          value: 'NORMAL'
        }, {
          label: '其他',
          value: 'ABNORMAL'
        }
      ],
      studentSignState: [
        {
          label: '未考勤',
          value: 'true'
        }
      ],
      signOnType: [
        {
          label: '考勤机',
          value: 'ATTENDANCE_MACHINE'
        }, {
          label: '手工',
          value: 'MANUAL'
        }
      ],
      exportDailog: false,
      exportTime: {
        startDate: '',
        endDate: '',
        studentName: ''
      },
      exportDailogNew: false,
      exportTimeNew: {
        serviceQueryType: '',
        exportDate: ''
      },
      serviceQueryType: [
        {
          label: '全托',
          value: 'ALL'
        },
        {
          label: '午托',
          value: 'MORNING'
        },
        {
          label: '下午托',
          value: 'AFTERNOON'
        },
        {
          label: '作业托',
          value: 'AFTERNOON1'
        }
      ],
      exportRules: {
        startDate: [
          { required: true, message: '请选择开始时间', trigger: 'change' }
        ],
        endDate: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      },
      exportRulesNew: {
        serviceQueryType: [
          { required: true, message: '请选择托管类型', trigger: 'change' }
        ],
        exportDate: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ]
      },
      pickerOptions: commonData.DATE_INITIAL_VALUE,
      daterangeTime: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      dataKaoqin: ''
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    onExport (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiStudentAttendance.exportStudentAttendanceV2.call(this, this.exportTime, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    onExportNew (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.exportTimeNew.serviceQueryType.indexOf('AFT') > -1) {
            this.exportTimeNew.serviceQueryType = 'AFTERNOON'
          }
          ApiStudentAttendance.exportZoneStudentAttendancesV2.call(this, this.exportTimeNew, (res) => {
            window.open(res.result)
          })
        }
      })
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'zoneStudent.student.studentName',
          operator: 'like',
          value: ''
        }, {
          field: 'attendDate',
          operator: 'eq',
          value: ''
        }, {
          field: 'morningSignState',
          operator: 'eq',
          value: ''
        }]
      }
      this.daterangeTime = [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      this.dataKaoqin = ''
      this.onSearch()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      // console.log(this.daterangeTime)
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    onLoading () {
      switch (this.loadtextNum.length) {
        case 0:
          this.loadtextNum = '.'
          break
        case 1:
          this.loadtextNum = '..'
          break
        case 2:
          this.loadtextNum = '...'
          break
        default:
          this.loadtextNum = ''
          break
      }
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      // const time = setInterval(this.onLoading, 500)
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: null,
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'attendDate'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      if (this.daterangeTime && this.daterangeTime.length > 1) {
        data.data = {
          startDate: this.daterangeTime[0],
          endDate: this.daterangeTime[1]
        }
      }
      if (this.dataKaoqin) {
        data.data = Object.assign(data.data, { noAttendance: 'true' })
      }
      ApiStudentAttendance.getStudentAttendanceList.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        // clearInterval(time)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
