// import lang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
// // 设置语言
// locale.use(lang)
import Vue from 'vue'
import request from '@/config/request.js'
// import tools from '@/util/tools'
import setting from '@/config/settings'
// const validate = tools.validate
const ApiUser = request.UserLogin
export default {
  name: 'header-nav',
  data () {
    // const validPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error(' '))
    //     return false
    //   } else if (!validate.isvalidPhone(value)) {
    //     callback(new Error(' '))
    //     return false
    //   } else {
    //     callback()
    //   }
    // }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.passInfo.confirmPassword !== '') {
          this.$refs.headerPassForm.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      language: 'zh',
      lang: [{
        // 模板语法的一种
        label: this.$t('message.zh'),
        value: 'zh'
      }, {
        label: this.$t('message.en'),
        value: 'en'
      }],
      qiniu: setting.gbs.qiniu,
      isCollapse: false,
      value: '',
      userInfo: JSON.parse(this.$store.state.login.userInfo),
      dropIcon: 'el-icon-arrow-down',
      userDialog: false,
      passWordDialog: false,
      formLabelWidth: '120px',
      passRules: {
        password: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      passInfo: {
        mobile: JSON.parse(this.$store.state.login.userInfo).mobile,
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      feedbackDialog: false,
      feedbackForm: {
        feedback: ''
      },
      feedbackRules: {
        feedback: [
          { required: true, message: '请输入您需要提的意见或者建议', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    route () {
      return this.$route
    }
  },
  watch: {
    // 侦听器
    language: function (val) {
      // 侦听单选按钮的变化，从而进行切换语言
      val === 'zh' ? this.$i18n.locale = 'zh' : this.$i18n.locale = 'en'
      Vue.set(this.lang, 'zh', { label: this.$t('message.zh'), value: 'zh' })
      Vue.set(this.lang, 'en', { label: this.$t('message.en'), value: 'en' })

      var Days = 30
      var exp = new Date()
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
      document.cookie = 'BELL_PLAY_LANG' + '=' + escape(val) + ';expires=' + exp.toGMTString()
    }
  },
  mounted () {
    this.$store.dispatch('setRouteList', this.$route)
  },
  methods: {
    feedbackSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.feedbackSave.call(this, { content: that.feedbackForm.feedback }, data => {
            that.$message({
              message: data.result,
              type: 'success'
            })
            this.feedbackDialog = false
          })
        }
      })
    },
    onDropDown (e) {
      if (e) {
        this.$data.dropIcon = 'el-icon-arrow-up'
      } else {
        this.$data.dropIcon = 'el-icon-arrow-down'
      }
    },
    changeMenu (e) {
      this.isCollapse = e
      this.$store.dispatch('changeIsCollapse', e)
    },
    onUpdatePassWord (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.changPassword.call(this, that.passInfo, data => {
            that.$message({
              message: '修改成功',
              type: 'success'
            })
            this.passWordDialog = false
          })
        }
      })
    },
    onSave (formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          ApiUser.updateSelfInfo.call(this, that.info, data => {
            ApiUser.getUserInfo.call(this, {}, userinfo => {
              that.$store.dispatch('setUserInfo', JSON.stringify(userinfo.data))
              that.$message({
                message: '保存成功',
                type: 'success'
              })
              this.userDialog = false
            })
          })
        }
      })
    },
    selectCommand (val) {
      if (val === 'info') {
        this.info = {
          trueName: this.userInfo.trueName,
          phone: this.userInfo.phone
        }
        this.userDialog = true
      }
      if (val === 'password') {
        this.passWordDialog = true
      }
      if (val === 'loginout') {
        this.$confirm('确定退出系统吗？, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('delUserLogin')
          this.$router.push(
            {
              path: '/login'
            }
          )
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          })
        })
      }
      if (val === 'feedback') {
        this.feedbackDialog = true
      }
    }
  }
}
