export default {
  // 获取指定数据
  partData (basicData, fields) {
    var returnData = {}
    if (typeof fields === 'object') {
      fields.map(item => {
        if (basicData[item] === null || basicData[item] === undefined) {
          basicData[item] = ''
        }
        returnData[item] = basicData[item]
      })
    }
    return returnData
  }
}
