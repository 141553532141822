import request from '@/config/request.js'
const ApiCourseWareQuery = request.Course.CourseWareQuery
export default {
  name: 'courseware-query',
  data () {
    return {
      search_data: {
        filters: [{
          field: 'teacher.mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'position',
          operator: 'eq',
          value: ''
        }]
      },
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      position: [
        {
          name: '总部授权',
          value: 'PRINCIPAL'
        },
        {
          name: '校区资源',
          value: 'TEACHING_GROUP_LEADER'
        }
      ],
      DetailFormVisible: false,
      formLabelWidth: '140px',
      details: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getDetails (row) {
      this.details = row
      this.DetailFormVisible = true
    },
    resetTable () {
      this.search_data = {
        filters: [{
          field: 'teacher.mobile',
          operator: 'like',
          value: ''
        }, {
          field: 'position',
          operator: 'eq',
          value: ''
        }]
      }
      this.onSearch()
    },
    /**
     * 搜索事件
     */
    onSearch () {
      // var sd = {}
      // var query = this.$route.query
      // for (var p in query) {
      //   sd[p] = query[p]
      // }
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value) {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    /**
     * 获取用户信息列表
     * @param  {number} options.page      当前页码，切换页码时用
     * @param  {number} options.page_size 每页显示数量，改变每页数量时用
     * @param  {function} options.fn                            } 获取列表后的回调函数
     */
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: {
          resourceType: 'ALL'
        },
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        var queryArr = []
        for (var s in this.search_data.filters) {
          if (this.search_data.filters[s].value) {
            queryArr.push(this.search_data.filters[s])
          }
        }
        data = Object.assign(data, { filters: queryArr } || {})
      }
      ApiCourseWareQuery.pageZoneCourseware.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
