import request from '@/config/request.js'
import { Message } from 'element-ui'
// import { Message } from 'element-ui'
// import Modules from '@/components/modules'
// import RouteView from '@/components/routeView'
const ApiLogin = request.UserLogin
export default {
  name: 'synchro',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    onSynchro () {
      this.loading = true
      ApiLogin.getSynchro.call(this, {}, res => {
        Message({
          message: '开始同步，请等待！！',
          type: 'success'
        })
        this.loading = false
      }, () => {
        this.loading = false
      })
    }
  },
  mounted () {
  }
}
