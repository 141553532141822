export default {
  name: 'lesson-management-class',
  props: ['classTable'],
  data () {
    return {
      // 需要给分页组件传的信息
      paginations: {
        current_page: parseInt(this.$route.query.page) || 1,
        total: 0,
        page_size: 10,
        page_sizes: [10, 20, 30],
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      tableList: [],
      position: [
        {
          name: '总部授权',
          value: 'PRINCIPAL'
        },
        {
          name: '校区资源',
          value: 'TEACHING_GROUP_LEADER'
        }
      ],
      DetailFormVisible: false,
      formLabelWidth: '140px',
      details: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
  },
  mounted () {
    // this.getList()
  }
}
