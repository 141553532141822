export default {
  name: 'left-menu',
  data () {
    return {
      // menuList: JSON.parse(this.$store.state.login.rootMenu)
      // menuList: [
      // ]
      menuList: [],
      menuList1: [{
        text: '校长首页',
        icon: 'bell-icon-shouye',
        path: '/home/index',
        nodes: []
      }, {
        text: '教务管理',
        icon: 'bell-icon-jiaowuguanli',
        nodes: [{
          menuPath: 'education/student-management',
          text: '学员管理'
        }, {
          menuPath: 'education/class-management',
          text: '班级管理'
        }, {
          menuPath: 'education/teacher-management',
          text: '老师管理'
        }, {
          menuPath: 'education/student-attendance',
          text: '学生考勤'
        }, {
          menuPath: 'education/teacher-attendance',
          text: '老师考勤'
        }
        ]
      }, {
        text: '文件管理',
        icon: 'bell-icon-wenjian',
        nodes: [{
          menuPath: 'file/admissions',
          text: '资料下载'
        }]
      }, {
        text: '直播课堂',
        icon: 'bell-icon-dianshizhiboguankanshipinbofangmeiti',
        nodes: [{
          menuPath: 'course/course-management',
          text: '课程管理'
        }, {
          menuPath: 'course/courseware-query',
          text: '课件查询'
        }, {
          menuPath: 'course/lesson-management',
          text: '课节费用管理'
        }, {
          menuPath: 'course/course-class-management',
          text: '线上班级管理'
        }]
      }, {
        text: '测评中心',
        icon: 'bell-icon-shouye',
        nodes: [{
          menuPath: '/evaluation/concentration',
          text: '专注力测评'
        }, {
          menuPath: '/evaluation/teacher-assessment',
          text: '老师教研库'
        }]
      }, {
        text: '用户管理',
        icon: 'bell-icon-yonghuguanli',
        nodes: [{
          menuPath: '/user/user',
          text: '用户列表'
        }, {
          menuPath: '/user/role',
          text: '角色管理'
        }, {
          menuPath: '/user/authority',
          text: '权限列表'
        }]
      }]
    }
  },

  mounted () {
    // console.log(this.$store.state.login.teacherStatus, 'this.$store.state.login.teacherStatus')
    // this.$nextTick(() => {
    //   this.menuList = [{
    //     text: '校长首页',
    //     show: true,
    //     icon: 'bell-icon-shouye',
    //     path: '/home/index',
    //     nodes: []
    //   }, {
    //     text: '教务管理',
    //     show: true,
    //     icon: 'bell-icon-jiaowuguanli',
    //     nodes: [{
    //       menuPath: 'education/student-management',
    //       text: '学员管理'
    //     }, {
    //       menuPath: 'education/class-management',
    //       text: '班级管理'
    //     }, {
    //       menuPath: 'education/teacher-management',
    //       text: '老师管理'
    //     }, {
    //       menuPath: 'education/student-attendance',
    //       text: '学生考勤'
    //     }, {
    //       menuPath: 'education/teacher-attendance',
    //       text: '老师考勤'
    //     }
    //     ]
    //   }, {
    //     text: '测评中心',
    //     show: localStorage.getItem('bell_admin_login_teacher') ? localStorage.getItem('bell_admin_login_teacher') : false,
    //     icon: 'bell-icon-shouye',
    //     nodes: [{
    //       menuPath: '/evaluation/concentration',
    //       text: '专注力测评'
    //     }, {
    //       menuPath: '/evaluation/teacher-assessment',
    //       text: '老师测评库'
    //     }]
    //   }, {
    //     text: '老师测评库',
    //     show: true,
    //     icon: 'bell-icon-zonghecepingguanli',
    //     path: '/teacher-assessment/teacher-assessment',
    //     nodes: []
    //   }, {
    //     text: '商城管理',
    //     show: true,
    //     icon: 'bell-icon-shangcheng',
    //     nodes: [{
    //       menuPath: '/mall/commodity',
    //       text: '积分商城'
    //     }, {
    //       menuPath: '/mall/record',
    //       text: '兑换记录'
    //     }]
    //   }, {
    //     text: '用户管理',
    //     show: true,
    //     icon: 'bell-icon-yonghuguanli',
    //     nodes: [{
    //       menuPath: '/user/user',
    //       text: '用户列表'
    //     }, {
    //       menuPath: '/user/role',
    //       text: '角色管理'
    //     }, {
    //       menuPath: '/user/authority',
    //       text: '权限列表'
    //     }]
    //   }
    //   ]
    //
    //   console.log(this.menuList, '123')
    // })
    const menu = JSON.parse(this.$store.state.login.rootMenu)
    this.menuList = menu.map(item => {
      if (item.singleMenu) {
        if (item.children) {
          item.path = item.path + item.children[0].path
        }
        return item
      } else {
        return item
      }
    })
    console.log(menu, 'menu')
  },
  watch: {
    $route () {
      this.addTags()
    }
  },
  computed: {
    route () {
      return this.$route
    },
    isCollapse () {
      return this.$store.state.header.isCollapse
    }
  },
  methods: {
    handleOpen (key, keyPath) {
    },
    handleClose (key, keyPath) {
    },
    onReplace (str) {
      let path = str.split('.html').join('')
      if (path[0] !== '/') {
        path = '/' + path
      }
      return path
    },
    addTags () {
      const route = this.$route// 获取地址栏路由
      if (route.path !== '/home/index') {
        this.$store.dispatch('setRouteList', route)
        this.$store.dispatch('addCachedView', route)
      }
    }
  }
}
